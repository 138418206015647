.navigation {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.29;

	nav {
		> ul {
			display: flex;
			flex-direction: column;

			> li {
				position: relative;
				transition: 0.3s;
				cursor: pointer;
				color: rgba(255, 255, 255, 0.8);

				svg {
					fill: rgba(255, 255, 255, 0.8);
					flex-shrink: 0;

					transition: 0.3s;
					width: 1.7143rem;
					height: 1.7143rem;
				}

				.arrow {
					margin-left: auto;
					width: 1.1429rem;
					height: 1.1429rem;

					&.open {
						transform: rotate(-180deg);
					}
				}

				&.current {
					> a,
					> span {
						color: var(--color-major);
						text-shadow: 0px 0px 0.7143rem rgba(252, 213, 53, 0.55);

						svg {
							fill: var(--color-major);
						}
					}
				}
				.trigger.open {
					color: var(--color-major);
					text-shadow: 0px 0px 0.7143rem rgba(252, 213, 53, 0.55);

					svg {
						fill: var(--color-major);
					}
				}

				> a,
				> span {
					display: flex;
					align-items: center;

					gap: 0.5714rem;

					transition: 0.3s;
					padding: 0.5714rem 1.7143rem;
					text-decoration: none;

					&:hover {
						color: var(--color-major);

						svg {
							fill: var(--color-major);
						}
					}
				}

				> ul {
					display: flex;
					flex-direction: column;
					padding: 0 2.2857rem;
					padding-right: 0;
					padding-bottom: 0.5714rem;

					font-size: 1rem;

					> li {
						color: rgba(255, 255, 255, 0.85);

						&.current {
							color: var(--color-major);
							text-shadow: 0px 0px 0.7143rem rgba(252, 213, 53, 0.55);
						}

						a {
							display: flex;
							align-items: center;
							gap: 0.6429rem;

							padding: 0.5714rem 1.7143rem;
							text-decoration: none;
						}

						&:last-child {
							margin: 0;
						}

						.icon {
							width: 1.2857rem;
							height: 1.2857rem;

							svg {
								fill: var(--color-major);
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 991.98px) {
	.navigation nav > ul > li {
		> ul {
		}
	}
}
