@import '../../../styles/variables.scss';

.faq {
  margin-top: -10px;
  background: #15161c;
  border-radius: 20px;

  position: relative;
  z-index: 3;

  padding-top: 85px;
  padding-bottom: 110px;

  @media (max-width: 991.98px) {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  @media (max-width: 575.98px) {
    padding-top: 56px;
    padding-bottom: 52px;
  }
}

.wrapper {
  position: relative;
  z-index: 2;
}

.links {
  overflow: hidden;
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -29px;

    @media (max-width: 767.98px) {
      margin: 0;
    }
    li {
      margin: 29px;
      width: calc(100% / 2 - 58px);

      @media (max-width: 767.98px) {
        width: 100%;
        margin: 0px 0px 20px 0px;
        &:last-child {
          margin: 0;
        }
      }
      a {
        text-transform: uppercase;

        padding: 33px 70px;
        cursor: pointer;
        transition: 0.3s;

        background: rgba(255, 255, 255, 0.03);
        border-radius: 20px;
        overflow: hidden;

        display: flex;
        align-items: center;

        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        @media (max-width: 767.98px) {
          padding: 33px 41px;
          padding-right: 50px;
        }

        &:hover {
          &::before {
            left: 50%;
            transform: scale(1.5);
          }
        }

        .icon {
          margin-right: 20px;

          width: 56px;
          height: 56px;
          flex-shrink: 0;

          @media (max-width: 575.98px) {
            margin-right: 22px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .arrow {
          margin-left: 8px;

          width: 19px;
          height: 19px;
          flex-shrink: 0;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          @media (max-width: 575.98px) {
            margin-left: 5px;
            margin-bottom: 5px;
            align-self: flex-end;
          }

          svg {
            width: 100%;
            height: 100%;
            svg {
              fill: var(--color-white);
            }
          }
        }

        position: relative;
        &::before {
          position: absolute;
          content: '';
          width: 722.45px;
          height: 253.52px;
          left: 0;
          top: -26px;

          transition: 0.3s;

          background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(233, 198, 0, 0.15) 0%,
            rgba(233, 198, 0, 0) 100%
          );
          border-radius: 50%;
        }
      }
    }
  }
}
