.burger {
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	width: 2.2143rem;
	height: 2.2143rem;

	svg {
		fill: var(--color-major);

		transition: 0.3s;
		width: 100%;
		height: 100%;
	}

	&:hover {
		svg {
			fill: var(--color-white);
		}
	}
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 24;
	border-bottom-right-radius: 1.1429rem;
	border-bottom-left-radius: 1.1429rem;
	background-color: var(--color-main);
	padding: 0.7143rem 0;
	width: 100%;
	height: 4.0714rem;
	color: var(--color-white);

	@media (max-width: 991.98px) {
		padding: 0.7143rem 0;
		height: 4.0714rem;
	}
}

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.inner {
	display: flex;
	align-items: center;

	gap: 1.1429rem;
}

.logo {
	display: block;
	margin-right: 3.8571rem;
	width: 7.4286rem;
	height: 2.5rem;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: contain;
		object-fit: contain;
	}

	@media (max-width: 991.98px) {
		margin: 0 1.0714rem;
		width: 7.7857rem;
		height: 2.7143rem;
	}
}

.profile {
}
