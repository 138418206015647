@import '../../../styles/variables.scss';

.social {
	ul {
		display: flex;
		align-items: center;
		margin: -10.5px;

		li {
			margin: 10.5px;

			a {
				display: flex;

				position: relative;
				flex-shrink: 0;
				justify-content: center;
				align-items: center;

				transition: 0.3s;
				width: 35px;
				height: 35px;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}

.menu {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;

	flex-direction: column;
	z-index: 20;
	transition: 0.4s;
	box-shadow: 0px 0.2857rem 2.8571rem rgba(0, 0, 0, 0.2);
	border-top-right-radius: 1.0714rem;
	border-bottom-right-radius: 1.0714rem;
	background: var(--color-grey);

	padding: 1rem 0;
	padding-bottom: 0;

	width: 16.5714rem;

	height: 100%;
	overflow-y: auto;
	color: var(--color-white);

	&.active {
		left: 0;
		visibility: visible;
	}
}

.close {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 2.2143rem;
	height: 2.2143rem;

	svg {
		fill: var(--color-major);

		transition: 0.3s;
		width: 100%;
		height: 100%;
	}

	&:hover {
		svg {
			fill: var(--color-white);
		}
	}
}

.head {
	display: flex;
	align-items: center;

	gap: 1.1429rem;

	padding: 0.5714rem 1.1429rem;
}

.content {
	display: flex;
	flex-direction: column;

	gap: 1.7143rem;
	height: 100%;
}

.logo {
	display: block;
	margin-right: 3.8571rem;
	width: 7.4286rem;
	height: 2.5rem;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: contain;
		object-fit: contain;
	}

	@media (max-width: 991.98px) {
		margin: 0 1.0714rem;
		width: 7.7857rem;
		height: 2.7143rem;
	}
}

.bottom {
	margin-top: auto;
	border-top: 1px solid #2a2a2ac6;

	padding-top: 1.4286rem;
	padding-bottom: 0.7143rem;
}
