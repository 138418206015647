@import '../../../styles/variables.scss';
.footer {
	position: relative;
	z-index: 2;
	padding: 40px 0;

	@media screen and (max-width: 1024px) {
		padding: 32px 0;
	}

	@media screen and (max-width: 767px) {
		padding: 24px 0 0;
	}
}

.header {
	.title {
		margin-bottom: 36px;
		color: var(--color-white);
		font-style: normal;
		font-weight: 700;
		font-size: 33px;
		line-height: 1.12;

		@media screen and (max-width: 1024px) {
			margin-bottom: 29px;

			font-size: 30px;
		}

		@media screen and (max-width: 767px) {
			margin-bottom: 27px;

			font-size: 24px;
		}
	}
}

.content {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: 767px) {
		flex-direction: column;
		align-items: flex-start;

		gap: 24px;
	}
}

.contacts {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;

	gap: 10px;

	@media screen and (max-width: 767px) {
		justify-content: flex-start;
		align-self: flex-start;
		gap: 24px;
	}

	&Item {
		a {
			position: relative;
			transition: 0.3s;
			padding: 0 0 0 38px;
			color: var(--color-white);
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 1;
			text-decoration: none;

			&:hover {
				color: var(--color-major);

				svg {
					fill: var(--color-major);
				}
			}

			svg {
				fill: var(--color-white);
				display: block;
				position: absolute;
				top: 50%;
				bottom: auto;
				transform: translateY(-50%);

				transition: 0.3s;
				width: 24px;
				height: 24px;
			}
		}
	}
}

.terms {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	text-align: right;

	@media screen and (max-width: 767px) {
		align-items: center;
		margin-left: -15px;
		background: var(--color-dark);
		padding: 8px 10px;
		width: calc(100% + 30px);
		text-align: center;
		text-align: center;
	}

	a {
		transition: 0.3s;
		border-bottom: 1px solid var(--color-white);
		color: var(--color-white);
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.3;
		text-align: center;

		&:not(:last-of-type) {
			margin: 0 0 5px;
		}

		&:hover {
			border-color: var(--color-major);
			color: var(--color-major);
		}
	}

	@media screen and (max-width: 1024px) {
		a {
			font-size: 16px;
		}
	}

	@media screen and (max-width: 767px) {
		a {
			font-size: 14px;
		}
	}
}

.socials {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	padding: 0 100px;
	min-height: 34px;

	@media screen and (max-width: 1024px) {
		padding: 0 70px;
	}

	@media screen and (max-width: 767px) {
		justify-content: space-between;

		gap: 24px;
		padding: 0;
	}

	&:before {
		display: block;
		position: absolute;
		top: 50%;
		bottom: auto;
		left: 0;
		transform: translateY(-50%);
		background: hsla(0, 0%, 100%, 0.3);
		width: 1px;
		height: 34px;
		content: '';

		@media screen and (max-width: 1024px) {
			height: 24px;
		}

		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	&:after {
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
		bottom: auto;
		transform: translateY(-50%);
		background: hsla(0, 0%, 100%, 0.3);
		width: 1px;
		height: 34px;
		content: '';

		@media screen and (max-width: 1024px) {
			height: 24px;
		}

		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	&Item {
		display: flex;

		flex-shrink: 0;
		justify-content: center;

		align-items: center;

		transition: 0.3s;
		width: 34px;
		height: 34px;

		@media screen and (max-width: 1024px) {
			width: 30px;
			height: 30px;
		}

		@media screen and (max-width: 767px) {
			width: 34px;
			height: 34px;
		}

		&:not(:last-child) {
			margin-right: 50px;

			@media screen and (max-width: 1024px) {
				margin-right: 20px;
			}

			@media screen and (max-width: 767px) {
				margin-right: 0;
			}
		}

		&:hover {
			opacity: 0.8;
		}

		img {
			fill: var(--color-white);
			transition: all 0.3s ease;

			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
