@import '../../../styles/variables.scss';

.hero {
  padding-top: 127px;

  overflow: hidden;

  position: relative;

  @media (max-width: 575.98px) {
    padding-top: 101px;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    @media (max-width: 767.98px) {
      top: -120px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;

      @media (max-width: 767.98px) {
        object-fit: contain;
      }
    }
  }

  &__money {
    transition: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  z-index: 3;
}
.inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  position: relative;
  &::before {
    position: absolute;
    content: '';
    height: 836.2146606445312px;
    width: 1600px;
    left: 50%;
    transform: translateX(-50%);
    top: -63.4609375px;
    border-radius: 50%;
    pointer-events: none;

    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(252, 213, 53, 0.2) 0%,
      rgba(252, 213, 53, 0) 100%
    );

    @media (max-width: 767.98px) {
      width: 100%;
      height: 773px;
      top: -46px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(252, 213, 53, 0.2) 0%,
        rgba(252, 213, 53, 0) 100%
      );
    }
  }

  & > * {
    position: relative;
    z-index: 2;
  }
}

.logo {
  margin: 0px 0px 76px 0px;

  text-align: center;

  &__image {
    height: 47px;
    width: 215px;
    margin: 0px 0px 9px 0px;

    @media (max-width: 575.98px) {
      height: 32px;
      width: 152px;

      margin: 0px 0px 6px 0px;
    }

    opacity: 0.8;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  font-weight: 600;
  font-size: 20.2139px;
  line-height: 25px;

  letter-spacing: 0.12em;
  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.85);

  @media (max-width: 575.98px) {
    margin: 0px 0px 51px 0px;

    font-size: 13.6234px;
    line-height: 17px;
  }
}

.text {
  margin: 0px 0px 46px 0px;

  font-weight: 700;
  font-size: 40px;
  line-height: 49px;

  color: rgba(255, 255, 255, 0.85);

  @media (max-width: 575.98px) {
    font-size: 30px;
    line-height: 130.9%;

    margin: 0px 0px 26px 0px;
  }
}

.title {
  margin: 58px 0px 0px 0px;
  font-weight: 700;
  font-size: 86.9663px;
  line-height: 106px;
  text-transform: uppercase;

  background: linear-gradient(179.66deg, #fcd535 0.3%, rgba(252, 213, 53, 0) 143.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (max-width: 991.98px) {
    font-size: 70px;
  }

  @media (max-width: 575.98px) {
    margin: 70px 0px 0px 0px;
    font-size: 50px;
    line-height: 61px;
  }
}

.button {
  margin: 0px 0px 9px 0px;

  a,
  button {
    padding-left: 63px;
    padding-right: 63px;
  }
}

.image {
  margin-top: -38px;
  pointer-events: none;
  width: 100%;

  padding: 0px 0px 31.1% 0px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (max-width: 767.98px) {
      object-fit: cover;
      object-position: top;
    }
  }

  @media (max-width: 767.98px) {
    padding: 0px 0px 78.5% 0px;

    width: calc(100% + 30px);

    margin-top: -71px;
  }
}

.items {
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 52px;

  @media (max-width: 575.98px) {
    padding-top: 32px;
  }

  &__line {
    position: absolute;
    top: -125px;
    left: 50%;
    transform: translateX(-50%);

    height: 314px;

    max-width: 571px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: 575.98px) {
      display: none;
    }
  }

  &::before {
    position: absolute;
    content: '';
    height: 513.2942504882812px;
    width: 1600px;
    pointer-events: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(252, 213, 53, 0.02) 0%,
      rgba(252, 213, 53, 0) 100%
    );

    @media (max-width: 575.98px) {
      top: -50px;
      transform: translateX(-50%);
      width: 2px;
      height: calc(100% + 100px);

      background: linear-gradient(180deg, #fcd535 -87.18%, rgba(252, 213, 53, 0) 104.31%);
    }
  }
  ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 2;

    margin: -14px;

    @media (max-width: 575.98px) {
      margin: 0;
      flex-direction: column;
    }

    li {
      margin: 14px;

      max-width: 256px;
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background: #202020;
      border-radius: 10px;

      overflow: hidden;

      padding: 25px;
      padding-bottom: 23px;

      color: rgba(255, 255, 255, 0.7);

      @media (max-width: 575.98px) {
        margin: 0px 0px 22px 0px;
        &:last-child {
          margin: 0;
        }

        font-size: 15px;
        line-height: 145.77%;
        padding: 25px;
      }

      span {
        font-weight: 700;
      }

      strong {
        margin: 5px 0px 0px 0px;
        display: block;
        font-weight: 700;
        font-size: 25px;
        line-height: 145.77%;
        color: var(--color-major);

        @media (max-width: 575.98px) {
          font-size: 23px;
          line-height: 145.77%;
        }
      }

      & > * {
        position: relative;
        z-index: 2;
      }

      position: relative;
      &::before {
        position: absolute;
        content: '';
        width: 470.79px;
        height: 448.67px;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);

        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(233, 198, 0, 0.15) 0%,
          rgba(233, 198, 0, 0) 100%
        );
        border-radius: 50%;
        pointer-events: none;
      }
    }
  }
}
