.balance {
	position: relative;

	.trigger {
		transition: 0.3s;

		cursor: pointer;

		color: var(--color-major);
		user-select: none;

		&:hover {
			color: var(--color-white);
		}
	}
}

.dropdown {
	position: absolute;
	top: calc(100% + 0.7143rem);
	right: 0;
	z-index: 10;

	transition: 0.3s;
	box-shadow: 0px 0.3571rem 1.4286rem var(--color-grey);
	border-radius: 0.4286rem;
	background: rgba(45, 51, 64, 0.95);
	padding: 0.8571rem;
	width: 11.2857rem;

	ul {
		li {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin: 0px 0px 0.8571rem 0px;
			color: rgba(255, 255, 255, 0.8);
			font-weight: 500;
			font-size: 0.8571rem;
			line-height: 1.0714rem;

			&:nth-child(4) {
				color: var(--color-success);
			}

			&:nth-child(3) {
				color: rgb(228, 12, 12);
			}

			&:nth-child(2) {
				color: rgb(228, 174, 12);
			}

			// &:nth-child(1) {
			// 	color: var(--color-major);
			// }

			b {
				display: block;
				margin-top: 0.5rem;
				font-size: 1.0714rem;
				line-height: 1.2857rem;
			}
		}
	}
}
