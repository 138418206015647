.balance {
	position: relative;

	.trigger {
		display: flex;
		position: relative;
		align-items: center;

		gap: 0.7143rem;

		gap: 0.5714rem;
		transition: 0.3s;

		cursor: pointer;
		padding: 0.5714rem 1.7143rem;
		width: 100%;
		color: rgba(255, 255, 255, 0.8);

		svg {
			fill: rgba(255, 255, 255, 0.8);
			flex-shrink: 0;

			transition: 0.3s;
			width: 1.7143rem;
			height: 1.7143rem;
		}

		.arrow {
			margin-left: auto;
			width: 1.1429rem;
			height: 1.1429rem;

			&.open {
				transform: rotate(-180deg);
			}
		}

		&:hover {
			color: var(--color-white);

			svg {
				fill: var(--color-white);
			}
		}

		&.open {
			color: var(--color-major);
			text-shadow: 0px 0px 0.7143rem rgba(252, 213, 53, 0.55);

			svg {
				fill: var(--color-major);
			}
		}
	}
}

.dropdown {
	position: absolute;
	bottom: calc(100% + 0.7143rem);
	left: 50%;
	transform: translate(-50%);
	z-index: 10;

	transition: 0.3s;
	box-shadow: 0px 0.3571rem 1.4286rem var(--color-grey);
	border-radius: 0.4286rem;
	background: var(--color-grey);
	padding: 0;
	width: calc(100% - 1.1429rem);
	min-width: 11.2857rem;
}

.create {
	display: flex;
	justify-content: center;
	align-items: center;

	margin-top: 1.1429rem;
	padding: 0.7143rem 1.1429rem;
	width: 100%;

	font-size: 0.8571rem;
	text-align: center;
}
