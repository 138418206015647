@import '../../../styles/variables.scss';

.breadcrumb {
  margin: 0px 0px 32px 0px;

  @media (max-width: 575.98px) {
    margin: 0px 0px 38px 0px;
  }

  ol,
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;

      font-weight: 700;
      font-size: 18px;
      line-height: 152.4%;

      a {
        text-decoration: none;
        color: var(--color-white);
        opacity: 0.3;

        &:hover {
          opacity: 0.8;
        }
      }

      &::after {
        content: '/';
        margin: 0 5px;
        color: var(--color-white);
        opacity: 0.3;
      }

      &:last-of-type {
        opacity: 0.3;
        &::after {
          display: none;
        }
      }
    }
  }
}
