@import '/node_modules/normalize.css/normalize.css';

:root {
	--color-white: #ffffff;
	--color-black: #000000;
	--color-main: #1f2229;
	--color-dark: #15161c;
	--color-dark2: #181a20;
	--color-text: #14151b;
	--color-text2: #24262b;
	--color-silver: #57575c;
	--color-silver67: #676970;
	--color-major: #fcd535;
	--color-page: #1e1e1e;
	--color-success: #24d861;
	--color-error: #ff3333;
	--color-modal: #13151a;
}

html {
	font-size: 14px;
}

body {
	-webkit-tap-highlight-color: transparent;
	background: var(--color-dark2);
	color: var(--color-white);
	font-weight: 400;
	font-size: inherit;
	line-height: 140%;
	font-family: 'Montserrat', sans-serif;

	&.lock {
		overflow: hidden;
	}
}

html,
body {
	height: 100%;
}

main {
	position: relative;
	padding-top: 5.9286rem;
	padding-bottom: 3.5714rem;
	min-height: 100vh;
}

.table > :not(caption) > * > * {
	padding: 0;
}

/* Global styles */

.iresponsive {
	position: relative;

	img,
	video {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		border: none;
		width: 100%;
		height: 100%;
	}
}

.icon {
	display: inline-flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;

	svg {
		fill: inherit;
		transition: 0.3s;
		width: 100%;
		height: 100%;
	}
}

.container-full {
	margin: 0 auto;
	padding: 0 1.7143rem;
	width: 100%;
	max-width: 100%;
}

.btn-copy {
	display: inline-flex;
	align-items: center;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	transition: 0.3s;
	cursor: pointer;
	outline: none;
	border-radius: 7.1429rem;
	background: rgba(255, 255, 255, 0.2);
	padding: 0.4286rem 1rem;
	color: var(--color-white);
	font-weight: 600;
	font-size: 0.7143rem;
	line-height: 0.8571rem;

	&:hover {
		background: rgba(255, 255, 255, 0.4);
	}
}

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.content {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.content__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.title-section {
	margin: 0;
	font-weight: 600;
	font-size: 1.2857rem;
	line-height: 1.5714rem;
}

.title {
	margin: 0;
	color: rgba(255, 255, 255, 0.9);
	font-weight: 600;
	font-size: 1.5625rem;
	line-height: 1.875rem;
}

.btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
	cursor: pointer;
	outline: none;
	box-shadow: 0rem 0rem 0.7143rem rgba(252, 213, 53, 0.4);
	border-radius: 0.4286rem;
	background: var(--color-major);
	padding: 0.9286rem 1.4286rem;
	min-width: 9.8571rem;
	color: var(--color-text);
	font-weight: 600;
	font-size: 0.9286rem;
	line-height: 1.1429rem;

	&:hover {
		transform: translateY(-0.1429rem);
		color: var(--color-black);

		.icon svg {
			fill: var(--color-black);
		}
	}

	&:active {
		transform: translateY(0);
	}

	&:disabled {
		pointer-events: none;
	}

	.icon {
		margin-left: 0.5rem;
		width: 0.8571rem;
		height: 0.8571rem;

		svg {
			fill: var(--color-text);
		}
	}
}

.btn--rounded {
	box-shadow: 0rem 0rem 0.7143rem rgba(219, 187, 51, 0.2);
	border-radius: 7.1429rem;
	background: rgba(252, 213, 53, 0.85);
	padding: 0.4286rem 1.0714rem;
	min-width: 7.7857rem;
	color: var(--color-text2);
	font-weight: 700;
	font-size: 0.7143rem;
	line-height: 0.8571rem;

	.icon svg {
		fill: var(--color-text2);
	}

	&:hover {
		background: rgb(252, 213, 53);
	}
}

.btn--silver {
	box-shadow: none;
	background: rgba(255, 255, 255, 0.2);
	padding: 0.4286rem 0.7143rem;
	min-width: 4.4286rem;
	color: var(--color-white);
	font-weight: 600;
	font-size: 0.7143rem;
	line-height: 0.8571rem;

	&:hover {
		background: rgba(255, 255, 255, 0.4);
		color: var(--color-white);
	}
}

.btn--minor {
	box-shadow: none;
	background: rgba(255, 255, 255, 0.1);
	color: rgba(255, 255, 255, 0.7);

	&:hover {
		background: rgba(255, 255, 255, 0.4);
		color: var(--color-white);
	}
}

.simplebar-track {
	border-radius: 0.7143rem;
	background: rgba(217, 217, 217, 0.2);

	&.simplebar-horizontal {
		right: 0.7143rem;
		left: 0.7143rem;
		height: 0.2143rem;

		.simplebar-scrollbar {
			top: 0;
			height: 0.2143rem;

			&::before {
				opacity: 0 !important;
			}
		}
	}
}

.simplebar-scrollbar {
	border-radius: 0.7143rem;
	background-color: var(--color-major);
}

.simplebar-track.simplebar-vertical {
	width: 0.1429rem;
}

.daterangepicker {
	border: 0;
	border-radius: 1.0714rem;
	background-color: #383b40;
	color: var(--color-white);
	font-weight: 400;
	font-size: 0.9286rem;
	line-height: 1.1429rem;

	&::before,
	&::after {
		border-bottom-color: #383b40;
	}

	.calendar-table {
		border: 0;
		background-color: #383b40;

		.next,
		.prev {
			border-radius: 0.4286rem;
		}

		.next span,
		.prev span {
			transition: 0.3s;
			border-color: var(--color-white);
			color: var(--color-white);
		}
	}

	td.available:hover,
	th.available:hover {
		background: var(--color-major);
	}

	td.available:hover span,
	th.available:hover span {
		border-color: var(--color-black);
		color: var(--color-black);
	}

	.calendar-table {
		td,
		th {
			transition: 0.3s;
		}

		thead tr:last-child th {
			border-bottom: 0.0714rem solid rgba(255, 255, 255, 0.2);
		}

		tbody {
			tr,
			td {
				border-color: rgba(255, 255, 255, 0.2);
			}
		}

		th,
		td {
			border-radius: 0;
			font-weight: 500;
		}
	}

	td {
		&.in-range {
			background: rgba(252, 213, 53, 0.2);
			color: var(--color-white);
		}

		&.off {
			background: transparent;
			color: rgba(255, 255, 255, 0.3);
		}
	}

	.drp-buttons {
		border: 0;

		.btn {
			border-radius: 0.4286rem;
			padding: 0.5357rem 1.4286rem;
			min-width: 6rem;
			font-weight: 600;
			font-size: 0.9286rem;
			line-height: 1.1429rem;

			&.btn-default {
				box-shadow: none;
				background: rgba(255, 255, 255, 0.15);
				color: var(--color-white);

				&:hover {
					background: rgba(255, 255, 255, 0.3);
					color: var(--color-white);
				}
			}
		}
	}

	td.active {
		background: var(--color-major);
		color: #383b40;

		&:hover {
			background: var(--color-major);
			color: #383b40;
		}
	}
}

.ct-series-b {
	.ct-bar,
	.ct-line,
	.ct-point,
	.ct-slice-donut {
		stroke: #26ad56;
	}
}

.datepicker-input {
	display: flex;
	position: relative;
	align-items: center;

	.react-datepicker-popper {
		z-index: 15;
	}

	input {
		display: flex;
		align-items: center;
		transition: 0.3s;
		border: 0.0714rem solid rgba(255, 255, 255, 0.3);
		border-radius: 0.7143rem;
		background: rgba(217, 217, 217, 0.05);
		padding: 0.6429rem 1.2143rem;
		padding-right: 3.4286rem;
		width: 100%;
		color: var(--color-white);
		font-weight: 600;
		font-size: 0.8571rem;
		line-height: 1.0714rem;

		&:focus {
			border-color: var(--color-major);
		}
	}
}

.datepicker-input__icons {
	display: flex;
	position: absolute;
	top: 50%;
	right: 0.7143rem;
	align-items: center;
	transform: translateY(-50%);
	pointer-events: none;
}

.datepicker-input__icon {
	transition: 0.3s;
	margin-right: 0.3571rem;
	width: 1.2857rem;
	height: 1.2857rem;

	svg {
		fill: var(--color-major);
	}

	&:last-child {
		margin: 0;
	}
}

.datepicker-input__icon-arrow {
	width: 0.4286rem;
	height: 0.5rem;
}

.select > span {
	display: block;
	color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	font-size: 0.9286rem;
	line-height: 1.1429rem;
}

.select2-container--default {
	.select2-selection--single .select2-selection__rendered {
		line-height: 1.0714rem;
	}

	&.select2-container--open
		.select2-selection--single
		.select2-selection__arrow
		b {
		transform: translate(-50%, -50%) rotate(-180deg);
	}

	.select2-selection--single {
		display: flex;
		align-items: center;
		border: 0;
		border-radius: 0.7143rem !important;
		background: #33353a;
		height: 2.5rem;
		font-weight: 600;
		font-size: 0.8571rem;
		line-height: 1.0714rem;

		.select2-selection__rendered {
			padding-right: 2.8571rem;
			padding-left: 1.4286rem;
			color: var(--color-white);
		}

		.select2-selection__arrow {
			top: 50%;
			right: 1.0714rem;
			transform: translateY(-50%);

			b {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				transition: 0.3s;
				margin: 0;
				border: 0;
				background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 1.5L5.5 5.5L9.5 1.5' stroke='white' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				padding: 0;
				width: 0.7143rem;
				height: 0.7143rem;
			}
		}
	}

	.select2-results__option--highlighted.select2-results__option--selectable {
		background-color: transparent;
	}

	.select2-results__option--selected {
		background-color: transparent !important;
		pointer-events: none;
		color: var(--color-major) !important;
	}

	.select2-results > .select2-results__options {
		font-weight: 600;
		font-size: 0.8571rem;
		line-height: 1.0714rem;
	}

	.select2-search--dropdown .select2-search__field {
		border: 0.0714rem solid rgba(255, 255, 255, 0.1);
		border-radius: 0.7143rem;
		background: rgba(255, 255, 255, 0.03);
		padding: 0.3571rem 0.7143rem;
		color: var(--color-white);
		font-weight: 500;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}

	.select2-results__option {
		transition: 0.3s;
		padding: 0.4286rem 1.2857rem;

		&.select2-results__option--disabled {
			display: none;
		}

		&:hover {
			background: rgba(255, 255, 255, 0.05);
			color: var(--color-white);
		}

		span {
			display: flex;
			align-items: center;
			gap: 0.3571rem;

			img {
				flex-shrink: 0;
				border-radius: 7.1429rem;
				width: 1.0714rem;
				height: 1.0714rem;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}
	}
}

.select-type-input + .select2-container--default .select2-selection--single {
	border: 0.0714rem solid rgba(255, 255, 255, 0.1);
	border-radius: 0.7143rem;
	background: rgba(255, 255, 255, 0.03);
	height: 3.4286rem;
	font-weight: 500;
	font-size: 0.9286rem;
	line-height: 1.1429rem;

	.select2-selection__rendered {
		padding-left: 1.7143rem;

		span {
			display: flex;
			align-items: center;
			gap: 0.7143rem;

			img {
				flex-shrink: 0;
				border-radius: 7.1429rem;
				width: 1.4286rem;
				height: 1.2857rem;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}
	}
}

.select2-dropdown {
	margin-top: 0.5714rem;
	border: 0;
	border-radius: 1.0714rem !important;
	background: #33353a;
	padding-top: 0.4286rem;
	padding-bottom: 0.4286rem;
	overflow: hidden;
}

.table {
	position: relative;
	margin: 0;
	border: 0.0714rem solid #474747;
	border-radius: 0.7143rem;
	width: 100%;
	overflow-x: auto;
}

.table--large table {
	min-width: 121.5rem;
}

.table__empty {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 5;
	pointer-events: none;
	color: rgba(255, 255, 255, 0.3);
	font-weight: 500;
	font-size: 1.4286rem;
	line-height: 1.7143rem;
	text-align: center;
}

.table table {
	margin: 0;
	border-collapse: collapse;
	width: 100%;
	color: rgba(255, 255, 255, 0.9);
	font-size: 1.0714rem;
	line-height: 1.2857rem;
	text-align: center;

	th,
	td {
		border-collapse: collapse;
	}

	th,
	td {
		border-top: 0;
		border-right: 0.0714rem solid #474747;
		border-bottom: 0.0714rem solid #474747;
		padding: 1.6071rem 1.2857rem;
	}

	tr {
		min-height: 4.5rem;

		td {
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			min-width: 3rem;
			max-width: 32.1429rem;
			overflow: hidden;
			text-align: center;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	thead {
		font-weight: 600;

		tr {
			background: rgba(255, 255, 255, 0.01);

			td {
				&:last-child {
					border-right: 0;
				}

				span {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					gap: 0.7143rem;
				}
			}
		}
	}

	.f-arrows {
		display: flex;
		flex-direction: column;
		gap: 0.3571rem;
	}

	.f-arrow {
		opacity: 0.8;
		transition: 0.3s;
		cursor: pointer;
		width: 0.8571rem;
		height: 0.5rem;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:hover {
			opacity: 1;
		}

		svg {
			fill: var(--color-major);
		}
	}

	tbody tr {
		&:nth-child(even) {
			background: rgba(255, 255, 255, 0.01);
		}

		td {
			&:first-child a {
				color: var(--color-major);
				text-decoration: none;

				&:hover {
					color: var(--color-white);
				}
			}

			span {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				gap: 0.7143rem;
			}

			&:last-child {
				border-right: 0;
			}

			&::after {
				display: inline-block;
				vertical-align: top;
				min-height: 1.284rem;
				content: '';
			}

			.icons {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				gap: 0.8571rem;
			}

			.icon {
				opacity: 0.8;
				width: 1.4286rem;
				height: 1.4286rem;
				text-decoration: none;

				svg {
					fill: var(--color-white);
				}
			}

			a {
				text-decoration: underline;
			}

			button:hover svg,
			a:hover svg {
				fill: var(--color-major);
			}
		}

		&:last-child {
			border-bottom: 0;

			td {
				border-bottom: 0;
			}
		}
	}

	tfoot {
		font-weight: 600;

		tr {
			border-top: 0.0714rem solid #474747;

			&:nth-child(odd) {
				background: rgba(255, 255, 255, 0.01);
			}

			td {
				&:last-child {
					border-right: 0;
				}

				span {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					gap: 0.7143rem;
				}
			}
		}
	}
}

.input {
	display: block;
}

.input--small {
	.input__label {
		margin: 0rem 0rem 0.6429rem 0rem;
	}

	.input__field {
		input {
			padding: 0.6429rem 1rem;
		}

		.icon {
			right: 1.0714rem;
			width: 1.2857rem;
			height: 1.2857rem;
		}
	}
}

.input__label {
	margin-bottom: 0.8571rem;
	color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	font-size: 0.9286rem;
	line-height: 1.1429rem;
}

.input__wrapper {
	display: flex;
	align-items: center;
	gap: 1.1429rem;
}

.input__field {
	position: relative;

	textarea,
	input {
		display: block;
		transition: 0.3s;
		border: 0.0714rem solid rgba(255, 255, 255, 0.1);
		border-radius: 0.7143rem;
		background: rgba(255, 255, 255, 0.03);
		padding: 1rem 1.7143rem;
		width: 100%;
		color: var(--color-white);
		font-weight: 500;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}

	textarea::-moz-placeholder,
	input::-moz-placeholder,
	textarea:-ms-input-placeholder,
	input:-ms-input-placeholder,
	textarea::-ms-input-placeholder,
	input::-ms-input-placeholder,
	textarea::placeholder,
	input::placeholder {
		color: rgba(255, 255, 255, 0.5);
	}

	textarea:focus,
	input:focus {
		border-color: var(--color-major);
	}

	textarea:disabled + .input__button,
	input:disabled + .input__button,
	textarea:disabled + .icon,
	input:disabled + .icon {
		opacity: 0.3;
		pointer-events: none;
	}

	textarea {
		overflow-y: auto;
		resize: none;
	}

	.icon {
		position: absolute;
		top: 50%;
		right: 1.7857rem;
		transform: translateY(-50%);
		z-index: 1;
		transition: 0.3s;
		cursor: pointer;
		width: 1.4286rem;
		height: 1.4286rem;

		svg {
			fill: var(--color-major);
		}

		&:hover svg {
			fill: var(--color-white);
		}
	}
}

.input__button {
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.3;
	transition: 0.3s;
	cursor: pointer;
	width: 3.0714rem;
	height: 3.0714rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:hover {
		opacity: 1;
	}

	svg {
		fill: var(--color-white);
		width: 100%;
		height: 100%;
	}
}

.input__message {
	transition: 0.3s;
	margin-top: 0.5714rem;
	color: rgba(255, 255, 255, 0.7);
	font-weight: 400;
	font-size: 0.8571rem;
	line-height: 1.0714rem;

	&.error {
		color: var(--color-error);
	}

	&.success {
		color: var(--color-success);
	}
}

.input-file {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	gap: 0.7143rem;
	transition: 0.3s;
	cursor: pointer;
	border: 0.0714rem solid rgba(255, 255, 255, 0.1);
	border-radius: 0.7143rem;
	background: #1f2127;
	padding: 1rem 2rem;
	padding-right: 1.7857rem;
	color: var(--color-white);
	font-weight: 500;
	font-size: 0.9286rem;
	line-height: 1.1429rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:hover {
		border-color: var(--color-major);
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
		width: 0;
		height: 0;
		font-size: 0;

		&::-webkit-file-upload-button {
			visibility: hidden;
		}
	}
}

.input-file__icon {
	width: 1.4286rem;
	height: 1.4286rem;

	svg {
		fill: var(--color-major);
	}
}

.switch {
	display: flex;
	align-items: center;
	gap: 0.7857rem;
	color: rgba(255, 255, 255, 0.7);
	font-size: 0.9286rem;
	line-height: 1.1429rem;
}

.switch__slider {
	display: inline-block;
	position: relative;
	cursor: pointer;
	border-radius: 7.1429rem;
	background: #2f3036;
	width: 3.5714rem;
	height: 2rem;

	span {
		position: absolute;
		bottom: 0.2143rem;
		left: 0.3571rem;
		transition: 0.3s;
		border-radius: 50%;
		background-color: var(--color-white);
		width: 1.5714rem;
		height: 1.5714rem;
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 0;
		height: 0;
		font-size: 0;

		&:checked + span {
			transform: translateX(1.4286rem);
			box-shadow: 0rem 0rem 0.7143rem rgba(252, 213, 53, 0.55);
			background-color: var(--color-major);
		}
	}
}

.checkbox {
	display: inline-flex;
	position: relative;
	cursor: pointer;
	padding-left: 2.1429rem;
	color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	font-size: 0.9286rem;
	line-height: 1.1429rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	span::before {
		position: absolute;
		top: -0.0357rem;
		left: 0;
		transition: 0.3s;
		border: 0.0714rem solid rgba(255, 255, 255, 0.5);
		border-radius: 0.2857rem;
		width: 1.2143rem;
		height: 1.2143rem;
		content: '';
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		width: 0;
		height: 0;
		font-size: 0;

		&:checked ~ span::before {
			box-shadow: 0rem 0rem 0.7143rem rgba(252, 213, 53, 0.55);
			border-color: var(--color-major);
			background-color: var(--color-major);
		}
	}
}

.checkbox--rounded {
	padding-left: 1.7143rem;

	span {
		&::before {
			border-color: var(--color-major);
			border-radius: 50%;
			width: 1.0714rem;
			height: 1.0714rem;
		}

		&::after {
			position: absolute;
			top: 0.145rem;
			left: 0.19rem;
			visibility: hidden;
			opacity: 0;
			transition: 0.3s;
			box-shadow: 0rem 0rem 0.7143rem rgba(252, 213, 53, 0.55);
			border-radius: 50%;
			background-color: var(--color-major);
			width: 0.7143rem;
			height: 0.7143rem;
			content: '';
		}
	}

	input:checked ~ span {
		&::before {
			box-shadow: none;
			border-color: var(--color-major);
			background-color: transparent;
		}

		&::after {
			visibility: visible;
			opacity: 1;
		}
	}
}

.accordion:last-child {
	margin: 0;
}

.accordion__trigger {
	display: flex;
	align-items: center;
	gap: 1rem;
	transition: 0.3s;
	cursor: pointer;
	padding: 1.7857rem 2.8571rem;
	font-weight: 600;
	font-size: 1.0714rem;
	line-height: 1.2857rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.active {
		padding-bottom: 0.8929rem;

		.accordion__trigger-icon::after {
			opacity: 1;
		}
	}

	&:hover {
		color: var(--color-major);
	}
}

.accordion__trigger-icon {
	display: flex;
	position: relative;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	box-shadow: 0rem 0rem 0.7143rem rgba(252, 213, 53, 0.55);
	border-radius: 0.3571rem;
	background: var(--color-major);
	width: 1.7857rem;
	height: 1.7857rem;

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: 0.3s;
		border-radius: 0.2857rem;
		background-color: var(--color-main);
		width: 0.8571rem;
		height: 0.1429rem;
		content: '';
	}

	&::after {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: 0.3s;
		border-radius: 0.2857rem;
		background-color: var(--color-main);
		width: 0.1429rem;
		height: 0.8571rem;
		content: '';
	}
}

.accordion__content {
	transition: 0.3s;
	padding-right: 3rem;
	padding-left: 5.6429rem;
	font-size: 1.0714rem;
	line-height: 139.9%;

	&.active {
		padding-bottom: 1.7857rem;
	}
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3.2857rem;
	margin-top: 2.1429rem;
	border-radius: 0.7143rem;
	background: rgba(255, 255, 255, 0.03);
	padding: 1.5714rem;
	font-weight: 600;
	font-size: 1.0714rem;
	line-height: 1.2857rem;
}

.pagination__page {
	color: rgba(255, 255, 255, 0.3);
}

.pagination ul {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.7857rem;

	li {
		color: rgba(255, 255, 255, 0.5);

		&.current {
			pointer-events: none;

			a {
				color: var(--color-major);
			}
		}

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}

		a {
			display: flex;
			flex-shrink: 0;
			justify-content: center;
			align-items: center;

			&:hover {
				color: var(--color-white);
			}

			.icon {
				width: 1.2857rem;
				height: 1.2857rem;

				svg {
					fill: var(--color-white);
				}
			}

			&:hover svg {
				fill: var(--color-major);
			}
		}
	}
}

.back-link {
	display: inline-flex;
	align-items: center;
	gap: 0.3571rem;
	transition: 0.3s;
	cursor: pointer;
	margin-right: auto;
	border-radius: 7.1429rem;
	background: rgba(255, 255, 255, 0.1);
	padding: 0.2857rem 0.8571rem;
	color: rgba(255, 255, 255, 0.6);
	font-weight: 600;
	font-size: 0.9286rem;
	line-height: 1.1429rem;

	.icon {
		fill: rgba(255, 255, 255, 0.6);
		width: 0.5714rem;
		height: 0.5714rem;
	}

	&:hover {
		background: rgba(255, 255, 255, 0.2);
		color: rgba(255, 255, 255, 0.8);

		.icon {
			fill: rgba(255, 255, 255, 0.8);
		}
	}
}

.top__back {
	margin-bottom: 0.7857rem;
}

.top__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 0.7143rem 1.0714rem;
}

.top__title {
	margin: 0;
	font-weight: 600;
	font-size: 1.7857rem;
	line-height: 2.1429rem;
}

.header__logo {
}

.header__user {
	display: flex;
	justify-content: center;
	align-items: center;

	.icon {
		width: 1.7857rem;
		height: 1.7857rem;

		svg {
			fill: var(--color-major);
			width: 100%;
			height: 100%;
		}
	}
}

.header__menu-close {
	display: flex;
	position: absolute;
	top: 1.2143rem;
	right: 1.5714rem;
	justify-content: center;
	align-items: center;
	z-index: 5;

	.icon {
		width: 1.7857rem;
		height: 1.7857rem;

		svg {
			fill: var(--color-major);
			width: 100%;
			height: 100%;
		}
	}
}

.header__control {
	margin-left: 1.0714rem;
}

.header__overlay {
	position: fixed;
	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	z-index: 5;
	transition: 0.4s;
	background-color: var(--color-main);
	width: 100%;
	height: 100%;

	&.active {
		visibility: visible;
		opacity: 0.5;
	}
}

.header-control {
	display: flex;
	align-items: stretch;
	border-radius: 0.7143rem;
	background: #272a33;
	font-weight: 600;
	font-size: 0.8571rem;
	line-height: 1.0714rem;

	> * {
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 0.0714rem solid var(--color-main);
		padding: 0.5rem 0.6429rem;
		padding-bottom: 0.5714rem;

		&:first-child {
			padding-left: 1.4286rem;
		}

		&:last-child {
			margin: 0;
			padding-right: 1.4286rem;
		}
	}
}

.header-control__mail .icon {
	position: relative;
	cursor: pointer;
	width: 1.5714rem;
	height: 1.5714rem;

	small {
		display: block;
		position: absolute;
		right: -0.0714rem;
		bottom: 0.1429rem;
		box-shadow: 0px 0px 0.7143rem #fcd535;
		border-radius: 50%;
		background-color: var(--color-major);
		width: 0.5rem;
		height: 0.5rem;
	}

	svg {
		fill: rgba(255, 255, 255, 0.3);
	}

	&:hover svg {
		fill: var(--color-white);
	}
}

.header-control__username {
	padding: 0.3571rem 0.7143rem;
}

.header-control__balance {
	padding: 0.3571rem 0.7857rem;
}

.header-control__settings {
	padding: 0.3571rem 0.7143rem;

	&:hover {
		.icon {
			svg {
				// fill: var(--color-silver);
			}
		}
	}

	.icon {
		width: 1.5714rem;
		height: 1.5714rem;

		svg {
			fill: var(--color-silver67);
		}
	}
}

.header-control__exit {
	padding-left: 1.0714rem;

	.icon {
		width: 1.4286rem;
		height: 1.4286rem;

		svg {
			fill: var(--color-silver67);
		}

		&:hover svg {
			fill: var(--color-white);
		}
	}
}

.language {
	position: relative;

	&:hover {
		.language__trigger .language__arrow {
			transform: rotate(-180deg);

			svg {
				fill: var(--color-white);
			}
		}

		.language__dropdown {
			visibility: visible;
			opacity: 1;
			pointer-events: all;
		}
	}
}

.language__trigger {
	display: flex;
	align-items: center;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.language__icon {
	flex-shrink: 0;
	border-radius: 50%;
	width: 1.4286rem;
	height: 1.4286rem;
	overflow: auto;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}
}

.language__arrow {
	transition: 0.3s;
	margin-left: 0.2857rem;
	width: 0.4286rem;
	height: 0.4286rem;

	svg {
		fill: rgba(255, 255, 255, 0.7);
	}
}

.language__dropdown {
	position: absolute;
	top: calc(100% + 0.7143rem);
	right: 0;
	visibility: hidden;
	opacity: 0;
	z-index: 10;
	transition: 0.3s;
	box-shadow: 0px 0.3571rem 1.4286rem var(--color-grey);
	border-radius: 0.4286rem;
	background: rgba(45, 51, 64, 0.95);
	padding: 0.3571rem;
	width: 10.3571rem;
	pointer-events: none;

	&::before {
		position: absolute;
		bottom: 100%;
		left: 0;
		background-color: transparent;
		width: 100%;
		height: 0.7143rem;
		content: '';
	}

	ul li {
		display: flex;
		align-items: center;
		margin: 0px 0px 0.1429rem 0px;

		&:last-child {
			margin: 0;
		}

		&.active {
			pointer-events: none;

			a {
				background: rgb(45, 51, 64);
			}
		}

		a {
			display: flex;
			align-items: center;
			transition: 0.3s;
			cursor: pointer;
			border-radius: 0.4286rem;
			padding: 0.3571rem 0.7857rem;
			width: 100%;
			color: var(--color-white);
			font-weight: 500;
			font-size: 0.8571rem;
			line-height: 165.8%;

			.language__icon {
				margin-right: 0.4286rem;
			}

			&:hover {
				background: rgba(255, 255, 255, 0.3);
				color: var(--color-white);
			}
		}
	}
}

.header-settings {
	position: relative;

	&:hover .header-settings__trigger,
	.header-settings__trigger.active {
		transform: rotate(-180deg);

		svg {
			fill: var(--color-major);
		}
	}
}

/* .header-settings:hover .header-settings__dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
} */

.header-settings__trigger {
	transition: 0.3s;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.header-settings__dropdown {
	position: absolute;
	top: calc(100% + 0.7143rem);
	right: 0;
	z-index: 10;

	/* visibility: hidden;
  opacity: 0;
  pointer-events: none; */
	transition: 0.3s;
	box-shadow: 0px 0.3571rem 1.4286rem var(--color-grey);
	border-radius: 0.4286rem;
	background: rgba(45, 51, 64, 0.95);
	padding: 0.3571rem;
	width: 11.2857rem;

	&::before {
		position: absolute;
		bottom: 100%;
		left: 0;
		background-color: transparent;
		width: 100%;
		height: 0.7143rem;
		content: '';
	}

	ul li {
		display: flex;
		align-items: center;
		margin: 0px 0px 0.1429rem 0px;

		&:last-child {
			margin: 0;
		}

		&.active {
			pointer-events: none;

			a {
				color: var(--color-major);
			}
		}

		a {
			display: flex;
			align-items: center;
			transition: 0.3s;
			cursor: pointer;
			border-radius: 0.4286rem;
			padding: 0.3571rem 0.7857rem;
			width: 100%;
			color: var(--color-white);
			font-weight: 500;
			font-size: 0.8571rem;
			line-height: 165.8%;

			.language__icon {
				margin-right: 0.4286rem;
			}

			&:hover {
				background: rgba(255, 255, 255, 0.3);
				color: var(--color-white);
			}
		}
	}
}

.footer {
	position: relative;
	z-index: 2;
	border-top-right-radius: 1.1429rem;
	border-top-left-radius: 1.1429rem;
	background-color: var(--color-dark);
	padding: 1.5rem 0;
}

.footer__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer__links ul {
	display: flex;
	align-items: center;

	li {
		opacity: 0.7;
		color: rgba(255, 255, 255, 0.7);
		font-weight: 600;
		font-size: 1.0714rem;
		line-height: 140.4%;
		text-align: center;
		text-transform: uppercase;
	}
}

.footer__social {
	margin: 0 3.1429rem;

	ul li a {
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;

		&:hover .icon svg {
			fill: var(--color-major);
		}

		.icon {
			width: 2.2857rem;
			height: 2.2857rem;

			svg {
				fill: var(--color-silver);
			}
		}
	}
}

.navbar {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	z-index: 2;
	padding: 0;
	width: 16.4286rem;
}

.navbar__close {
	display: flex;
	position: absolute;
	top: 1.2143rem;
	left: 1.5714rem;
	justify-content: center;
	align-items: center;
	z-index: 5;

	.icon {
		width: 1.7857rem;
		height: 1.7857rem;

		svg {
			fill: var(--color-major);
			width: 100%;
			height: 100%;
		}
	}
}

.navbar__control {
	margin: 0px 0px 0.7143rem 0px;
}

.navbar__section {
	margin: 0px 0px 1.4286rem 0px;

	&:last-child {
		margin: 0;
	}
}

.navbar-control__icons {
	margin: 0px 0px 0.7143rem 0px;
	border-radius: 0.7143rem;
	background: rgba(255, 255, 255, 0.03);
	min-height: 1.8571rem;

	> * {
		flex: 1;
	}
}

.navbar-control__info {
	display: flex;
	border-radius: 0.7143rem;
	background: rgba(255, 255, 255, 0.03);
	padding: 1.5rem 1.5714rem;
	font-weight: 600;
	font-size: 1.1429rem;
	line-height: 1.4286rem;

	> * {
		padding: 0 0.7143rem;
		width: 50%;
	}

	.header-control__username span,
	.header-control__balance span {
		display: block;
		margin: 0px 0px 0.1429rem 0px;
		color: rgba(255, 255, 255, 0.5);
		font-weight: 500;
		font-size: 0.8571rem;
		line-height: 1.0714rem;
	}
}

.navbar-section {
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.05);
}

.navbar-section__head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 0.0714rem solid var(--color-main);
	padding: 1rem 1.4286rem;
}

.navbar-section__title {
	color: rgba(255, 255, 255, 0.9);
	font-weight: 700;
	font-size: 0.8571rem;
	line-height: 1.0714rem;
}

.navbar-section__icon {
	cursor: pointer;
	margin-left: 0.7143rem;
	width: 1.0714rem;
	height: 1.0714rem;

	svg {
		fill: rgba(191, 192, 194, 0.5);
	}

	&:hover svg {
		fill: var(--color-white);
	}
}

.navbar-section__body {
	padding: 1rem 1.4286rem;
}

.navbar-section__bonuses ul li {
	display: flex;
	flex-direction: column;
	margin: 0px 0px 1.6429rem 0px;
	color: rgba(255, 255, 255, 0.8);
	font-weight: 500;
	font-size: 0.8571rem;
	line-height: 1.0714rem;

	&:nth-child(1) {
		color: var(--color-major);
	}

	&:nth-child(2) {
		color: var(--color-success);
	}

	b {
		display: block;
		margin-top: 0.5rem;
		font-size: 1.0714rem;
		line-height: 1.2857rem;
	}
}

.navbar-section__count {
	display: flex;
	align-items: baseline;
	color: rgba(255, 255, 255, 0.7);
	font-weight: 500;
	font-size: 1.7714rem;
	line-height: 2.1429rem;

	.icon {
		margin-right: 0.5714rem;
		width: 2.4286rem;
		height: 2.4286rem;

		img {
			width: 100%;
			height: 100%;
			-o-object-fit: contain;
			object-fit: contain;
		}
	}
}

.navbar-section__button {
	margin: 0.9286rem 0px 0px 0px;
}

.navbar-section__manager ul li {
	display: flex;
	flex-direction: column;
	margin: 0px 0px 1.2143rem 0px;
	color: rgba(255, 255, 255, 0.9);
	font-weight: 500;
	font-size: 0.8571rem;
	line-height: 1.0714rem;

	small {
		display: block;
		margin-bottom: 0.3571rem;
		color: rgba(255, 255, 255, 0.6);
		font-size: inherit;
	}

	button {
		padding: 0;
		color: inherit;
		text-align: left;

		&:hover {
			color: var(--color-light);
		}
	}

	&:last-child {
		margin: 0;
	}

	.btn-copy {
		align-self: flex-start;
		margin-top: 0.4286rem;
		min-width: 7.4286rem;
	}
}

.settings-nav {
	position: relative;
	margin: 0px 0px 3.5714rem 0px;
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.03);
	padding: 1.1429rem 5.3571rem;
	padding-bottom: 0;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		border-radius: 1.0714rem;
		background: linear-gradient(
			270deg,
			#1f2127 29.48%,
			rgba(31, 33, 39, 0) 100%
		);
		width: 5.4286rem;
		height: 100%;
		pointer-events: none;
		content: '';
	}

	ul {
		display: flex;
		justify-content: space-between;
		align-items: center;
		overflow-y: auto;

		li {
			margin-right: 2.1429rem;
			color: rgba(255, 255, 255, 0.9);
			font-weight: 600;
			font-size: 0.9286rem;
			line-height: 1.1429rem;
			text-align: center;
			white-space: nowrap;

			&:last-child {
				margin: 0;
			}

			&.current,
			&.react-tabs__tab--selected {
				pointer-events: none;
				color: var(--color-major);

				a::before,
				button::before {
					width: 100%;
				}
			}

			a,
			button {
				display: block;
				position: relative;
				padding-bottom: 1.2143rem;
				width: 100%;

				color: inherit;

				&::before {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					z-index: 1;
					transition: 0.3s;
					border-radius: 0.4286rem;
					background-color: var(--color-major);
					width: 0;
					height: 0.2143rem;
					content: '';
				}

				&:hover {
					color: var(--color-major);

					&::before {
						width: 100%;
					}
				}
			}
		}
	}
}

.modal {
	/* position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(24, 26, 32, 0.5);
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  transition: all 0.5s ease-in-out; */

	&.active {
		visibility: visible;
		opacity: 1;

		.modal__content {
			transform: translate(0, 0);
			opacity: 1;
		}
	}
}

.modal__area {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.modal__body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2.8571rem 1.4286rem;
	width: 100%;
	min-height: 100vh;
}

.modal__content {
	transform: translate(0rem, -100%);
	opacity: 0;
	transition: all 0.3s ease-in-out;
	margin: 0 auto;
	box-shadow: 0rem 0.2857rem 2.8571rem rgba(10, 10, 10, 0.4);
	border-radius: 1.4286rem;
	background: var(--color-modal);
	padding: 2.6429rem 3.4286rem;
	padding-bottom: 2.7143rem;
	width: 100%;
	max-width: 35.7143rem;
}

.modal__close {
	position: absolute;
	top: 1.7143rem;
	right: 2.1429rem;
	opacity: 0.5;
	z-index: 101;
	transition: 0.3s;
	cursor: pointer;
	width: 1.0714rem;
	height: 1.0714rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:hover {
		opacity: 1;
	}

	svg {
		fill: var(--color-white);
	}
}

.modal__title {
	margin: 0rem 0rem 2.0714rem 0rem;
	color: rgba(255, 255, 255, 0.9);
	font-weight: 600;
	font-size: 1.6429rem;
	line-height: 2rem;
	text-align: center;
}

.modal-form__content {
	display: flex;
	flex-direction: column;
}

.modal-form__inputs {
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 10px;
}

.modal-form__input {
	margin-top: 5px;

	&:last-child {
		margin: 0;
	}

	.input__wrapper input {
		max-width: 6.2857rem;
	}

	&.select {
		.select2 {
			width: 100% !important;
		}

		.select2-container--default .select2-selection--single {
			border: 0.0714rem solid rgba(255, 255, 255, 0.1);
			border-radius: 0.7143rem;
			background: rgba(255, 255, 255, 0.03);
			height: 3.2857rem;
			font-weight: 500;
			font-size: 0.9286rem;
			line-height: 1.1429rem;

			.select2-selection__rendered {
				padding-left: 2rem;
			}

			.select2-selection__arrow {
				right: 1.7857rem;

				b {
					background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.25L6.5 6.75L12 1.25' stroke='%23FCD535' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					width: 0.9286rem;
					height: 0.9286rem;
				}
			}
		}
	}
}

.modal-form__button {
	align-self: center;
	margin-top: 1.9286rem;
	width: 100%;
	max-width: 13.0714rem;

	.btn {
		box-shadow: 0rem 0rem 0.7143rem rgba(219, 187, 51, 0.2);
		border-radius: 0.7143rem;
		padding: 0.6786rem 1.0714rem;
		width: 100%;
		font-weight: 700;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}
}

.information {
	position: relative;
	margin: 0px 0px 1.4286rem 0px;
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.03);
	padding: 1.6429rem;
	color: var(--color-white);

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(233, 198, 0, 0.15) 0%,
			rgba(233, 198, 0, 0) 100%
		);
		width: 110%;
		height: 14.2857rem;
		pointer-events: none;
		content: '';
	}

	> * {
		position: relative;
		z-index: 2;
	}
}

.information__label {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.8571rem 1.0714rem;
	color: rgba(255, 255, 255, 0.8);
	font-weight: 600;
	font-size: 1rem;
	line-height: 135.4%;
	text-align: center;
	text-transform: uppercase;

	.icon {
		transition: 0.3s;
		margin-left: 1rem;
		width: 0.7143rem;
		height: 0.7143rem;

		svg {
			fill: rgba(255, 255, 255, 0.8);
		}
	}
}

.information__list ul {
	display: flex;
	margin: -0.3571rem;

	li {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0.3571rem;

		.icon {
			margin-right: 0.7857rem;
			width: 1.7857rem;
			height: 1.7857rem;

			svg {
				fill: var(--color-major);
			}
		}

		span {
			font-weight: 500;
			font-size: 1.2857rem;
			line-height: 1.5714rem;

			+ small {
				align-self: flex-end;
				margin-bottom: 0.1429rem;
				margin-left: 0.4286rem;
			}
		}

		small {
			display: block;

			/* identical to box height */
			color: rgba(255, 255, 255, 0.6);
			font-weight: 700;
			font-size: 0.8571rem;
			line-height: 1.0714rem;
		}
	}
}

.banner {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	margin: 0px 0px 1.4286rem 0px;
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.03);
	padding: 2.8571rem 6.0714rem;
	min-height: 19.4286rem;
	overflow: hidden;
}

.banner__wrapper {
	position: relative;
	z-index: 2;
}

.banner__content {
	width: 100%;
	max-width: 19.2857rem;
}

.banner__title {
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	background: linear-gradient(
		179.66deg,
		#fcd535 0.3%,
		rgba(252, 213, 53, 0) 143.56%
	);
	-webkit-background-clip: text;
	background-clip: text;
	font-weight: 700;
	font-size: 2.5rem;
	line-height: 101.4%;
}

.banner__text {
	margin: 1rem 0px 0px 0px;
	width: 100%;
	max-width: 14.6429rem;
	color: rgba(255, 255, 255, 0.8);
	font-weight: 700;
	font-size: 1.0714rem;
	line-height: 1.2857rem;
}

.banner__button {
	margin: 2.0714rem 0px 0px 0px;
}

.banner__image {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 1;
	width: 81.5%;
	height: 100%;
	pointer-events: none;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: contain;
		object-fit: contain;
		-o-object-position: bottom right;
		object-position: bottom right;
	}
}

.latest-news {
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.05);
	width: 21.4286rem;
}

.latest-news__content {
	padding-bottom: 1.0714rem;
}

.latest-news__head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 0.0714rem solid var(--color-main);
	padding: 0.9286rem 1.0714rem;
	padding-bottom: 1rem;
}

.latest-news__title {
	display: flex;
	align-items: center;

	.icon {
		margin-right: 0.3571rem;
		width: 1.5714rem;
		height: 1.5714rem;

		svg {
			fill: var(--color-major);
		}
	}
}

.latest-news__button {
	margin-left: 0.7143rem;
}

.latest-news__items {
	padding: 1.0714rem;
	max-height: 37.8571rem;
	overflow-y: auto;
}

.latest-news__item {
	margin: 0px 0px 0.5714rem 0px;
}

.latest-news-item {
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.05);
	overflow: auto;
	color: rgba(255, 255, 255, 0.9);
	font-size: 0.8571rem;
	line-height: 1.0714rem;
}

.latest-news-item__image {
	display: block;
	border-radius: 1.0714rem;
	width: 100%;
	height: 9.6429rem;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}
}

.latest-news-item__content {
	padding: 1.0714rem 1.4286rem;
}

.latest-news-item__title {
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	margin: 0px 0px 0.3571rem 0px;
	overflow: hidden;
	font-weight: 700;
	text-overflow: ellipsis;
	white-space: normal;
}

.latest-news-item__text {
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	margin: 0px 0px 1.0714rem 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
}

.latest-news-item__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.latest-news-item__date {
	margin-right: 0.7143rem;
	color: rgba(255, 255, 255, 0.5);
}

.latest-news-item__button .btn {
	min-width: 3.9286rem;
}

.profile-chart {
	position: relative;
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.05);
	padding: 1.4286rem 1.7857rem;

	/* overflow: hidden; */
	width: calc(100% - 21.4286rem - 1.4286rem);
}

.profile-chart__modal {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 5;
	transition: 0.3s;
	background: rgba(24, 26, 32, 0.7);
	width: 100%;
	height: 100%;
}

.profile-chart__modal-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.05);
	padding: 2.5rem;
	text-align: center;

	b {
		font-weight: 700;
		font-size: 1.0714rem;
		line-height: 101.4%;
	}

	span {
		margin-top: 1.2143rem;
		width: 100%;
		max-width: 25.0714rem;
	}
}

.profile-chart__control {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.4286rem;
}

.chart-control__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 1.0714rem;
}

.chart-control-period {
	display: flex;
	align-items: center;
	font-size: 0.8571rem;
	line-height: 1.0714rem;
}

.chart-control-period__title {
	margin-right: 1.5rem;
	font-weight: 600;
}

.chart-control-period__select {
	width: 100%;
	max-width: 15.4286rem;
}

.chart-control-period__buttons {
	margin-left: 1.5rem;

	> ul {
		display: flex;
		align-items: center;
		margin: -0.3214rem;

		li {
			margin: 0.3214rem;

			&.active a {
				box-shadow: 0rem 0rem 0.7143rem rgba(252, 213, 53, 0.4);
				background: rgba(252, 213, 53, 0.85);
				pointer-events: none;
				color: var(--color-text);
			}

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				border-radius: 7.1429rem;
				background: rgba(255, 255, 255, 0.2);
				padding: 0.5rem 1.6786rem;
				color: var(--color-white);
				font-weight: 600;
				font-size: 0.8571rem;
				line-height: 1.0714rem;
				text-align: center;

				&:hover {
					background: rgba(255, 255, 255, 0.2);
					color: var(--color-white);
				}
			}
		}
	}
}

.settings {
	margin: 0 auto;
	width: 100%;
	max-width: 55.1429rem;
}

.settings__section {
	margin: 0rem 0rem 3.5714rem 0rem;

	&:last-child {
		margin: 0;
	}
}

.settings__button {
	margin: 2.8571rem 0rem 0rem 0rem;

	.btn {
		padding: 1.1429rem;
		min-width: 11.9286rem;
	}
}

.settings__title {
	margin: 0rem 0rem 2.1429rem 0rem;
}

.settings__text {
	font-weight: 400;
	font-size: 1.0714rem;
	line-height: 139.9%;

	p {
		word-wrap: break-word;
		margin: 0rem 0rem 0.5rem 0rem;

		&:last-child {
			margin: 0;
		}
	}

	a {
		text-decoration: underline;
	}

	ul {
		margin: 0.5rem 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		li {
			position: relative;
			padding-left: 1.6429rem;

			&::before {
				position: absolute;
				top: 0.6429rem;
				left: 0.7143rem;
				border-radius: 50%;
				background-color: var(--color-white);
				width: 0.1429rem;
				height: 0.1429rem;
				content: '';
			}
		}
	}
}

.settings-section__title {
	margin: 0rem 0rem 1.7143rem 0rem;
}

.settings-section__row {
	margin: 0rem 0rem 2.5rem 0rem;

	&:last-child {
		margin: 0;
	}
}

.settings-section__inputs--three {
	display: flex;
	flex-wrap: wrap;
	gap: 1.7143rem 2.5714rem;
}

.settings-section__select .select2 {
	width: 100% !important;
}

.settings-section__button .btn {
	background: rgba(255, 255, 255, 0.1);
	padding: 1.0714rem 2.1071rem;
	font-weight: 700;
	font-size: 0.9286rem;
	line-height: 1.1429rem;

	&:hover {
		background: rgba(255, 255, 255, 0.2);
	}
}

.settings-section__switch {
	min-height: 3.2857rem;
}

.main-settings__section {
	margin: 0rem 0rem 2.1429rem 0rem;

	&:last-child {
		margin: 0;
	}
}

.main-settings-section__title {
	display: inline-flex;
	align-items: center;
	gap: 0.6429rem;
	margin: 0rem 0rem 1.0714rem 0rem;
	font-weight: 600;
	font-size: 1.0714rem;
	line-height: 1.2857rem;

	small {
		display: inline-block;
		color: rgba(255, 255, 255, 0.7);
		font-weight: 500;
		font-size: 0.8571rem;
		line-height: 1.0714rem;
	}
}

.main-settings-section__inputs {
	display: flex;
	flex-wrap: wrap;
	margin: -0.4286rem;
}

.main-settings-section__input {
	margin: 0.4286rem;
	width: calc(48% - 0.8571rem);

	.input__field input {
		color: rgba(255, 255, 255, 0.5);
	}
}

.main-settings-section__save-button {
	margin: 10px 50px 0 auto;
	width: 80px;
	height: 35px;

	font-size: 1rem;
}

.main-settings-section__pagination {
	margin-top: 2.8571rem;
}

.faq__title {
	margin: 0px 0px 2.1429rem 0px;
}

.faq__accordions-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 3.2143rem;
}

.create-promo__steps {
	display: flex;
	justify-content: space-between;
	align-self: flex-start;
	gap: 2.1429rem;
	border: 0.1429rem solid var(--color-grey);

	border-radius: 1.4286rem;
	background-color: var(--color-main);

	padding: 1.7143rem;
}

.create-promo-steps__wrapper {
	display: flex;
	flex-direction: column;
	width: 24.2857rem;
}

.create-promo-steps__head {
	margin: 0rem 0rem 3rem 0rem;
}

.create-promo-steps__back {
	margin: 0rem 0rem 0.7857rem 0rem;
}

.create-promo-steps__triggers {
	width: 100%;
}

.create-promo-steps__trigger {
	margin: 0 0 1.4286rem 0;

	&:last-child {
		margin: 0;
	}
}

.create-promo-steps__sections {
	width: calc(100% - 24.2857rem - 2.1429rem);
}

.create-promo-steps__section {
	display: none;

	&.active {
		display: block;
	}
}

.create-promo-trigger {
	display: flex;
	flex-direction: column;
	border-radius: 0.7143rem;
	border-top-right-radius: 1.0714rem;
	border-top-left-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.03);

	&.active .create-promo-trigger__body {
		display: block;
	}

	&.current {
		.create-promo-trigger__body {
			display: block;
		}

		&.active {
			.create-promo-trigger__buttons,
			.create-promo-offers__selects {
				display: flex;
			}

			.create-promo-get-link__selects {
				display: block;
			}
		}
	}
}

.create-promo-trigger__head {
	display: flex;
	position: relative;
	align-items: baseline;
	gap: 1.2857rem;
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.03);
	padding: 1.1429rem 1.5714rem;
	overflow: hidden;

	&::before {
		position: absolute;
		top: -1rem;
		left: 50%;
		transform: translateX(-50%);
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(233, 198, 0, 0.15) 0%,
			rgba(233, 198, 0, 0) 100%
		);
		width: calc(100% + 4.2857rem);
		height: 8.2857rem;
		content: '';
	}

	> * {
		position: relative;
		z-index: 2;
	}
}

.create-promo-trigger__count {
	font-weight: 600;
	font-size: 1.4286rem;
	line-height: 1.7143rem;
}

.create-promo-trigger__title {
	font-size: 1.0714rem;
	line-height: 1.2857rem;
}

.create-promo-trigger__body {
	display: none;
	padding: 1.7143rem 1.5rem;
	padding-top: 1.4286rem;
}

.create-promo-trigger__buttons {
	display: none;
	justify-content: center;
	align-items: center;
	gap: 1.2857rem;
	margin-top: 1.4286rem;
}

.create-promo-trigger__button {
	width: 50%;

	.btn {
		gap: 0.5rem;
		padding: 0.2857rem 0.7143rem;
		padding: 0.6786rem 1.0714rem;
		width: 100%;
		min-width: 100%;
		font-weight: 700;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}
}

.create-promo-trigger__promo-type {
	color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	font-size: 0.9286rem;
	line-height: 1.1429rem;
	text-align: center;

	span {
		color: var(--color-white);
	}
}

.create-promo-offers__selects {
	display: none;
	justify-content: space-between;
	gap: 0.6429rem;
}

.create-promo-offers__select {
	flex: 1;

	.select2 {
		width: 100% !important;
	}

	> span {
		display: block;
		margin: 0rem 0rem 0.7143rem 0rem;
		color: rgba(255, 255, 255, 0.7);
		font-weight: 600;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}
}

.create-promo-offers__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 0.7143rem;
	background: rgba(255, 255, 255, 0.03);
	padding: 1.7857rem;
	text-align: center;
}

.create-promo-offers__logo {
	width: 7.3571rem;
	height: 2.5rem;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: contain;
		object-fit: contain;
	}
}

.create-promo-offers__list {
	margin: 1.2143rem 0rem 0rem 0rem;

	ul li {
		margin: 0rem 0rem 0.5rem 0rem;
		font-weight: 600;
		font-size: 0.9286rem;
		line-height: 1.1429rem;

		span:first-child {
			color: rgba(255, 255, 255, 0.7);
		}
	}
}

.create-promo-get-link__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.create-promo-get-link__title {
	margin-bottom: 0.7857rem;
	color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	font-size: 0.9286rem;
	line-height: 1.1429rem;
	text-align: center;
}

// .create-promo-get-link__image {
// 	transform: translateZ(0);
// 	border-top-right-radius: 1.4286rem;
// 	border-top-left-radius: 1.4286rem;
// 	width: 100%;
// 	max-width: 16.6429rem;
// 	height: 11.7143rem;
// 	overflow: hidden;

// 	img {
// 		width: 100%;
// 		height: 100%;
// 		-o-object-fit: contain;
// 		object-fit: contain;
// 	}
// }

.create-promo-get-link__image {
	position: relative;

	transform: translateZ(0);
	z-index: 2;
	border-top-right-radius: 1.4286rem;
	border-top-left-radius: 1.4286rem;
	width: 100%;
	max-width: 16.6429rem;
	height: 11.7143rem;

	overflow: hidden;

	iframe {
		-ms-transform: scale(0.1);
		-moz-transform: scale(0.1);
		-o-transform: scale(0.1);
		-webkit-transform: scale(0.1);
		transform: scale(0.1);

		-ms-transform-origin: 0 0;
		-moz-transform-origin: 0 0;
		-o-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		border: 0;

		width: 1200%;
		height: 114rem;
	}
}

.create-promo-get-link__selects {
	display: none;
	margin-top: 1.7857rem;
}

.create-promo-get-link__select {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.3571rem;
	margin-bottom: 1.0714rem;

	&:last-child {
		margin: 0;
	}

	.select2 {
		width: 100% !important;
		max-width: 10.1429rem;
	}

	> span {
		color: rgba(255, 255, 255, 0.7);
		font-weight: 600;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
		text-align: center;
	}
}

.create-promo-section__head {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: baseline;
	gap: 0.7857rem;
	margin-bottom: 1.4286rem;
	border-radius: 0.7143rem;
	background: rgba(255, 255, 255, 0.03);
	padding: 1.1429rem 1.5714rem;
	overflow: hidden;

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(233, 198, 0, 0.15) 0%,
			rgba(233, 198, 0, 0) 100%
		);
		width: calc(100% + 4.2857rem);
		height: 8.2857rem;
		content: '';
	}

	> * {
		position: relative;
		z-index: 2;
	}
}

.create-promo-section__title {
	display: flex;
	align-items: baseline;
	gap: 0.7857rem;
	color: rgba(255, 255, 255, 0.8);
	font-weight: 600;
	font-size: 1.4286rem;
	line-height: 1.7143rem;

	span {
		color: rgba(255, 255, 255, 0.7);
		font-weight: 500;
		font-size: 1.0714rem;
		line-height: 1.2857rem;
	}
}

.promo-offer__columns {
	display: flex;
	justify-content: space-between;
	gap: 0.8571rem;
}

.promo-offer__column {
	width: 33.3333333333%;
}

.promo-offer-column__title {
	margin-bottom: 1.1429rem;
	color: rgba(252, 213, 53, 0.7);
	text-align: center;
}

.promo-offer-column__item {
	margin-bottom: 1.2143rem;

	&:last-child {
		margin: 0;
	}
}

.promo-offer-item {
	display: block;
	position: relative;
	border-radius: 0.7143rem;
	background: rgba(255, 255, 255, 0.05);
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		width: 0;
		height: 0;
		font-size: 0;

		&:disabled {
			pointer-events: none;

			+ .promo-offer-item__selected {
				pointer-events: none;

				&::after {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		&:checked + .promo-offer-item__selected {
			&::before,
			span::after {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}

.promo-offer-item__content {
	display: flex;
	position: relative;
	flex-direction: column;
	z-index: 2;
	padding: 1.5rem 1.1429rem;
	padding-top: 2.4286rem;
}

.promo-offer-item__image {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 14rem;

	overflow: hidden;

	iframe {
		-ms-transform: scale(0.25);
		-moz-transform: scale(0.25);
		-o-transform: scale(0.25);
		-webkit-transform: scale(0.25);
		transform: scale(0.25);

		-ms-transform-origin: 0 0;
		-moz-transform-origin: 0 0;
		-o-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		border: 0;

		width: 400%;
		height: 56rem;
	}
}

.promo-offer-item__link {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.7143rem;
	color: rgba(255, 255, 255, 0.9);
	font-weight: 600;
	font-size: 1.0714rem;
	line-height: 1.2857rem;
	text-align: center;

	.icon {
		width: 1.4286rem;
		height: 1.4286rem;

		svg {
			fill: var(--color-white);
		}
	}
}

.promo-offer-item__wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.promo-offer-item__logo {
	display: block;
	align-self: center;
	margin-bottom: 1.4286rem;
	width: 7.7341rem;
	height: 2.7103rem;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: contain;
		object-fit: contain;
	}
}

.promo-offer-item__list {
	border-radius: 0.7143rem;
	background: rgba(255, 255, 255, 0.05);
	padding: 1.0714rem;

	ul li {
		margin: 0rem 0rem 0.5rem 0rem;
		font-weight: 600;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
		text-align: center;

		span:first-child {
			color: rgba(255, 255, 255, 0.7);
		}
	}
}

.promo-offer-item__languages {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.1429rem;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}
}

.promo-offer-item__selected {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-top: 0.0714rem solid var(--color-main);
	padding: 1.0714rem;
	padding-bottom: 1.5rem;
	color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	font-size: 0.9286rem;
	line-height: 1.1429rem;
	text-align: center;

	&::before {
		position: absolute;
		top: -4.2143rem;
		left: 0;
		visibility: hidden;
		opacity: 0;
		transition: 0.3s;
		border-radius: 50%;
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(233, 198, 0, 0.15) 0%,
			rgba(233, 198, 0, 0) 100%
		);
		width: calc(100% + 3.5rem);
		height: calc(100% + 16.9286rem);
		pointer-events: none;
		content: '';
	}

	&::after {
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
		opacity: 0;
		z-index: 5;
		transition: 0.3s;
		background: rgba(24, 26, 32, 0.7);
		width: 100%;
		height: 100%;
		content: '';
	}

	> * {
		position: relative;
		z-index: 2;
	}

	span {
		display: block;
		position: relative;
		padding-left: 2rem;

		&::before {
			position: absolute;
			top: 0.0714rem;
			left: 0;
			border: 1px solid var(--color-major);
			border-radius: 50%;
			width: 1.0714rem;
			height: 1.0714rem;
			content: '';
		}

		&::after {
			position: absolute;
			top: 0.2857rem;
			left: 0.1786rem;
			visibility: hidden;
			opacity: 0;
			transition: 0.3s;
			box-shadow: 0rem 0rem 0.7143rem rgba(252, 213, 53, 0.55);
			border-radius: 50%;
			background: var(--color-major);
			width: 0.7143rem;
			height: 0.7143rem;
			content: '';
		}
	}
}

.promo-type__items {
	display: flex;
	flex-wrap: wrap;
	gap: 0.8571rem 0.7143rem;
}

.promo-type__item {
	width: calc(33.3333333333% - 0.4762rem);
}

.promo-link__columns {
	display: flex;
	flex-wrap: wrap;
	gap: 0.8571rem 1.0714rem;
}

.promo-link__column {
	width: calc(33.3333333333% - 0.7142666667rem);
}

.promo-link__head {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
	gap: 1.0714rem;
	margin-bottom: 3.1429rem;
}

.promo-link__language-title {
	margin-bottom: 1.7143rem;
	color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	font-size: 0.9286rem;
	line-height: 1.1429rem;
}

.promo-link__language-list ul {
	display: flex;
	flex-wrap: wrap;
	gap: 1.7143rem;

	li {
		&.active a {
			box-shadow: 0rem 0rem 1.0714rem rgba(252, 213, 53, 0.3);
			background: #181a20;
			pointer-events: none;
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 0.5rem;
			transition: 0.3s;
			border-radius: 0.7143rem;
			background: rgba(255, 255, 255, 0.05);
			padding: 0.5rem 1.1429rem;
			color: rgba(255, 255, 255, 0.7);
			font-weight: 700;
			font-size: 0.9286rem;
			line-height: 1.1429rem;
			text-align: center;

			&:hover {
				opacity: 0.7;
			}

			img,
			picture {
				flex-shrink: 0;
				border-radius: 50%;
				width: 1.5rem;
				height: 1.5rem;
				overflow: hidden;
			}
		}
	}
}

.promo-link__add {
	display: flex;
	align-items: center;
	gap: 0.7857rem;
}

.promo-link__add-input {
	width: 100%;
	max-width: 6.2857rem;

	.input__field input {
		padding: 0.6786rem 1.4286rem;
	}
}

.promo-link__add-button {
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
	border: 0.1163rem solid rgba(255, 255, 255, 0.3);
	border-radius: 0.3333rem;
	width: 2.5rem;
	height: 2.5rem;

	&:hover {
		opacity: 0.7;
	}

	.icon {
		width: 1.2857rem;
		height: 1.2857rem;

		svg {
			fill: var(--color-white);
		}
	}
}

.promo-link-column__title {
	margin-bottom: 0.7857rem;
	color: rgba(255, 255, 255, 0.7);
	font-weight: 600;
	font-size: 0.9286rem;
	line-height: 1.1429rem;
}

.my-promo .table table {
	min-width: 132.1429rem;
}

.my-promo__head {
	display: flex;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-self: flex-end;
	gap: 1.0714rem;
	margin-bottom: 2.5714rem;

	.create-promo-steps__head {
		margin: 0;
	}
}

.my-promo__control {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	gap: 1.4286rem 1.7143rem;
}

.my-promo-control__select {
	display: flex;
	align-items: center;
	gap: 1.5714rem;

	> span {
		display: block;
		color: rgba(255, 255, 255, 0.7);
		font-weight: 600;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}

	.select2-container--default .select2-selection--single {
		background: rgba(255, 255, 255, 0.07);
	}
}

.my-promo-control .select2 {
	min-width: 10.1429rem;
}

.my-promo-control__search {
	width: 100%;
	max-width: 18.5714rem;
}

.my-promo-mail__period {
	margin-bottom: 2.3571rem;
}

.my-promo-mail__control {
	margin-bottom: 2rem;
}

.my-promo-mail__head {
	margin-bottom: 2.5rem;
}

.promo-mail-period {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	gap: 0.7143rem;
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.03);

	/* overflow: hidden; */
	padding: 1.2857rem 3.2143rem;
	min-height: 70px;
}

.promo-mail-period__shadow::before {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: radial-gradient(
		50% 50% at 50% 50%,
		rgba(233, 198, 0, 0.15) 0%,
		rgba(233, 198, 0, 0) 100%
	);
	width: 120%;
	height: 200%;
	pointer-events: none;
	content: '';
}

.promo-mail-period > * {
	position: relative;
	z-index: 2;
}

.promo-mail-period__shadow {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	overflow: hidden;
	content: '';
}

.promo-mail-period__title {
	color: rgba(255, 255, 255, 0.9);
	font-size: 1.0714rem;
	line-height: 1.2857rem;
}

.promo-mail-control {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
}

.promo-mail-control__select {
	display: flex;
	flex-direction: column;
	gap: 0.5714rem;

	> span {
		display: inline-block;
		color: rgba(255, 255, 255, 0.7);
		font-weight: 600;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}

	.select2 {
		width: 100% !important;
	}

	&.input input {
		text-align: center;
	}
}

.promo-mail-head {
	display: flex;
	align-items: flex-end;
	gap: 2.8571rem;
}

.my-promo-mail__space {
	display: flex;
	gap: 1.1rem;
}

.my-promo-mail__promoId {
	cursor: pointer;
	margin: 10px 0;
	border-radius: 5px;
	background: var(--color-main);
	padding: 10px 20px;
	width: max-content;
}

.promo-mail-head__input {
	margin-top: -0.7143rem;
	width: 100%;
	max-width: 11.2857rem;

	input {
		text-align: center;
	}
}

.promo-mail-head__select-wrapper {
	display: flex;
	flex-direction: column;
	gap: 0.9286rem;
	width: 100%;
	max-width: 15.4286rem;

	> span {
		display: inline-block;
		color: rgba(255, 255, 255, 0.7);
		font-weight: 600;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}
}

.promo-mail-head__select .select2 {
	width: 100% !important;
}

.promo-mail-head__checkboxs {
	display: flex;
	flex-direction: column;
	gap: 0.7143rem;
}

.promo-mail-head__buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.9286rem;

	.btn {
		border-radius: 0.7143rem;
		padding: 0.6786rem 2.8571rem;
		min-width: 10.0714rem;
		font-weight: 700;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}
}

.personal-promo {
	display: flex;
	flex-direction: column;
}

.personal-promo__back {
	margin-right: auto;
}

.personal-promo__content {
	position: relative;
	align-self: center;
	z-index: 2;
	margin-top: 2.3571rem;
	width: 100%;
	max-width: 28.8571rem;
}

.personal-promo__title {
	margin: 0rem 0rem 2.9286rem 0rem;
	color: rgba(255, 255, 255, 0.9);
	font-weight: 600;
	font-size: 1.7857rem;
	line-height: 2.1429rem;
	text-align: center;
}

.personal-promo__bg {
	position: absolute;
	pointer-events: none;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: contain;
		object-fit: contain;
	}
}

.personal-promo__bg--top {
	top: 2.8571rem;
	left: 0;
	width: 100%;
	height: 19.4343rem;
}

.personal-promo__bg--left {
	bottom: 0;
	left: 0;
	width: 45.0714rem;
	height: 39.2857rem;

	img {
		-o-object-position: bottom;
		object-position: bottom;
	}
}

.personal-promo__bg--center {
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 73.6429rem;
	height: 23.1221rem;

	img {
		-o-object-position: bottom;
		object-position: bottom;
	}
}

.personal-promo__bg--right {
	right: 0;
	bottom: 0;
	width: 39rem;
	height: 37.3571rem;

	img {
		-o-object-position: bottom;
		object-position: bottom;
	}
}

.personal-promo-form__item {
	margin-bottom: 1.2143rem;

	&:last-child {
		margin: 0;
	}

	.select2 {
		width: 100% !important;
	}

	&.input {
		textarea,
		input {
			border: 0.0714rem solid rgba(255, 255, 255, 0.1);
			border-radius: 0.7143rem;
			background: #1f2127;
			padding-right: 2rem;
			padding-left: 2rem;
			height: 3.2857rem;
			font-weight: 500;
			font-size: 0.9286rem;
			line-height: 1.1429rem;
		}

		textarea::-moz-placeholder,
		input::-moz-placeholder,
		textarea:-ms-input-placeholder,
		input:-ms-input-placeholder,
		textarea::-ms-input-placeholder,
		input::-ms-input-placeholder,
		textarea::placeholder,
		input::placeholder {
			color: var(--color-white);
		}
	}

	.select2-container--default .select2-selection--single {
		border: 0.0714rem solid rgba(255, 255, 255, 0.1);
		border-radius: 0.7143rem;
		background: #1f2127;
		height: 3.2857rem;
		font-weight: 500;
		font-size: 0.9286rem;
		line-height: 1.1429rem;

		.select2-selection__rendered {
			padding-left: 2rem;
		}

		.select2-selection__arrow {
			right: 1.7857rem;

			b {
				background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.25L6.5 6.75L12 1.25' stroke='%23FCD535' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
				width: 0.9286rem;
				height: 0.9286rem;
			}
		}
	}
}

.personal-promo-form__button {
	margin-top: 2.7143rem;

	.btn {
		padding: 1rem 1.4286rem;
		width: 100%;
		font-weight: 700;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}
}

.application__wrapper {
	position: relative;
}

.application__content {
	border-radius: 0.7143rem;
	background: rgba(255, 255, 255, 0.03);
	padding: 4.9286rem;
	padding-right: 0;
	padding-bottom: 4.7143rem;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 50%;
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(233, 198, 0, 0.15) 0%,
			rgba(233, 198, 0, 0) 100%
		);
		width: 105.0565rem;
		height: 107.4634rem;
		pointer-events: none;
		content: '';
	}
}

.application__inner {
	position: relative;
	z-index: 2;
	width: 100%;
	max-width: 35.7143rem;
}

.application__title {
	margin: 0rem 0rem 2.5714rem 0rem;
	color: var(--color-major);
	font-weight: 600;
	font-size: 1.4286rem;
	line-height: 1.7143rem;
	text-transform: uppercase;
}

.application__text {
	width: 100%;
	max-width: 27.1429rem;
	font-weight: 400;
	font-size: 1.0714rem;
	line-height: 163.9%;

	b {
		font-weight: 600;
	}

	p {
		margin: 0.7857rem 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul,
	ol {
		margin: 0.7857rem 0;
	}

	ul:first-child,
	ol:first-child {
		margin-top: 0;
	}

	ul:last-child,
	ol:last-child {
		margin-bottom: 0;
	}

	ul li {
		position: relative;
		padding-left: 1.6429rem;

		&::before {
			position: absolute;
			top: 0.75rem;
			left: 0.7143rem;
			border-radius: 50%;
			background-color: var(--color-white);
			width: 0.2143rem;
			height: 0.2143rem;
			content: '';
		}
	}
}

.application__buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.7143rem 2.2143rem;
	margin-top: 2.4286rem;
}

.application__button .btn {
	border-radius: 0.7143rem;
	padding: 1rem 3.1429rem;
	font-weight: 700;
	font-size: 0.9286rem;
	line-height: 1.1429rem;
}

.application__social ul {
	display: flex;
	align-items: center;
	gap: 2rem;

	li a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		width: 2.2143rem;
		height: 2.2143rem;

		&:hover .icon svg {
			fill: var(--color-white);
		}

		.icon {
			width: 100%;
			height: 100%;

			svg {
				fill: var(--color-major);
			}
		}
	}
}

.application__image {
	z-index: 1;
	pointer-events: none;

	img {
		width: 100%;
		height: 100%;
		-o-object-fit: contain;
		object-fit: contain;
		-o-object-position: bottom right;
		object-position: bottom right;
	}
}

.news__wrapper {
	margin-top: 1.7143rem;
}

.news__head {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 1.0714rem;
}

.news__period {
	display: flex;
	align-items: center;
	gap: 1.2857rem;

	> span {
		color: rgba(255, 255, 255, 0.7);
		font-weight: 600;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}

	.datepicker-input {
		width: 100%;
		max-width: 15.4286rem;
	}
}

.news__sorting ul {
	display: flex;
	align-items: center;
	gap: 1.4286rem;

	li {
		&.active a {
			box-shadow: 0rem 0rem 1.0714rem rgba(252, 213, 53, 0.3);
			background: var(--color-dark2);
			pointer-events: none;
		}

		a {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			transition: 0.3s;
			border-radius: 0.7143rem;
			background: rgba(255, 255, 255, 0.05);
			padding: 0.6786rem 1.4286rem;
			color: rgba(255, 255, 255, 0.7);
			font-weight: 700;
			font-size: 0.9286rem;
			line-height: 1.1429rem;
			text-align: center;

			&:hover {
				background: rgba(255, 255, 255, 0.1);
				color: var(--color-white);
			}
		}
	}
}

.news__body,
.news__pagination {
	margin-top: 3.5714rem;
}

.news-item {
	display: flex;
	align-items: stretch;
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.05);

	a.news-item__image:hover img {
		transform: scale(1.05);
	}
}

.news-item__image {
	display: flex;
	flex-direction: column;
	border-radius: 1.0714rem;
	background: rgba(255, 255, 255, 0.05);
	width: 53%;
	height: 100%;
	overflow: hidden;

	picture,
	img {
		transition: 0.3s;
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}
}

.news-item__wrapper {
	padding: 2.5rem 2.1429rem;
	width: 47%;
	color: rgba(255, 255, 255, 0.9);
	font-size: 1.0794rem;
	line-height: 1.2857rem;
}

.news-item__title {
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	margin-bottom: 0.8571rem;
	overflow: hidden;
	font-weight: 700;
	text-overflow: ellipsis;
	white-space: normal;
}

.news-item__text {
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
}

.news-item__inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.7143rem;
	margin-top: 1.8571rem;
}

.news-item__date {
	color: rgba(255, 255, 255, 0.5);
}

.news-item__button .btn {
	border-radius: 8.9947rem;
	padding: 0.4286rem 1.3571rem;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.2143rem;
}

.notifications__filter {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.notifications__wrapper {
	margin-top: 2.5714rem;
}

.notifications__pagination {
	margin-top: 1.4286rem;
}

.notifications-filter {
	gap: 1.0714rem 4.2857rem;

	.select {
		display: flex;
		align-items: center;
		gap: 1.2857rem;
	}
}

.notifications-filter__select .select2 {
	min-width: 10.1429rem;
}

.support__wrapper {
	margin-top: 2.5rem;
}

.finance__head {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 1.0714rem;
	margin-bottom: 2.0714rem;
}

.finance__head-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 1.0714rem 1.7143rem;

	.select {
		display: flex;
		align-items: center;
		gap: 1.1429rem;

		.select2 {
			min-width: 11.2143rem;
		}
	}

	+ .finance__button {
		margin: 0;
	}
}

.finance__button {
	margin: 0 auto;
	width: 100%;
	max-width: 10.0714rem;

	.btn {
		border-radius: 10.0002px;
		padding: 0.6786rem 1.0714rem;
		width: 100%;
		font-weight: 700;
		font-size: 13px;
		line-height: 1.1429rem;
	}
}

.statistics__period {
	margin-bottom: 2.3571rem;
}

.statistics__info-text {
	margin-top: -0.7143rem;
	margin-bottom: 1.6429rem;
	color: rgba(255, 255, 255, 0.9);
	font-size: 1.0714rem;
	line-height: 1.2857rem;
	text-align: center;
	text-align: center;
}

.statistics__head {
	margin-bottom: 2.9286rem;
}

.statistics__head-referral {
	margin-right: auto;
	width: 100%;
	max-width: 338px;

	.input__field input {
		padding-right: 2.8571rem;
	}
}

.statistics__head-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
	gap: 1.0714rem 1.7857rem;
	margin-bottom: 2.4286rem;

	.statistics__head-buttons .statistics__head-button .btn {
		padding-right: 2.0714rem;
		padding-left: 2.0714rem;
		min-width: 8.6429rem;
	}
}

.statistics__head-buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.9286rem;

	.btn {
		border-radius: 0.7143rem;
		padding: 0.6786rem 2.8571rem;
		min-width: 10.0714rem;
		font-weight: 700;
		font-size: 0.9286rem;
		line-height: 1.1429rem;
	}
}

.statistics__head-inner {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	gap: 1.0714rem 1.7857rem;

	&.space-between {
		justify-content: space-between;
	}

	&.flex-end {
		justify-content: flex-end;
	}
}

.statistics__head-inputs {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	gap: 0.7857rem;

	.input {
		width: 100%;
		max-width: 11.2857rem;

		input {
			text-align: center;
		}
	}
}

.statistics__head-checkboxs-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	gap: 1.0714rem 1.7857rem;

	.checkboxs {
		display: inline-flex;
		flex-direction: column;
		align-self: flex-start;
		gap: 0.7143rem;
		max-width: 11.4286rem;
	}
}

.statistics-control {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
	gap: 1.4286rem 1.8571rem;

	.select {
		display: flex;
		flex-direction: column;
		gap: 0.5714rem;

		> span {
			display: inline-block;
			color: rgba(255, 255, 255, 0.7);
			font-weight: 600;
			font-size: 0.9286rem;
			line-height: 1.1429rem;
		}

		.select2 {
			width: 100% !important;
		}
	}
}

.statistics-control__select.input input {
	text-align: center;
}

@media (min-width: 991px) {
	.information__list ul li {
		flex: 1;
	}
}

@media (min-width: 992px) {
	.header__user {
		display: none;
	}

	.header__menu {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;
	}

	.header__menu-close {
		display: none;
	}

	.header__navigation {
		margin-right: auto;
	}

	.header__overlay {
		display: none;
	}

	.navbar__close {
		display: none;
	}

	.navbar__wrapper {
		// border-radius: 1.0714rem;
		// background: var(--color-main);
		padding: 1.4286rem 0.8571rem;
		padding-top: 0;
	}

	.navbar__control {
		display: none;
	}

	.settings-nav + {
		.finance {
			margin-top: -1.4286rem;
		}

		.statistics {
			margin-top: -2.1429rem;
		}
	}

	.information {
		overflow: hidden;
	}

	.information__label {
		display: none;
	}

	.latest-news__item:last-child {
		margin: 0;
	}

	.chart-control__select {
		width: 100%;
		max-width: 10.1429rem;

		.select2 {
			width: 100% !important;
		}
	}

	.settings-section__inputs {
		display: flex;
		flex-wrap: wrap;
		gap: 1.7143rem 2.5714rem;

		.settings-section__input {
			width: calc(50% - 1.2857rem);
		}
	}

	.settings-section__inputs--three .settings-section__input {
		width: calc(33.3333333333% - 1.7142666667rem);
	}

	.settings-section__selects {
		display: flex;
		flex-wrap: wrap;
		gap: 1.7143rem 2.5714rem;

		.settings-section__select {
			width: calc(50% - 1.2857rem);
		}
	}

	.settings-section__columns {
		display: flex;
		flex-wrap: wrap;
		gap: 1.7143rem 2.5714rem;

		.settings-section__column {
			width: calc(33.3333333333% - 1.7142666667rem);
		}
	}

	.faq__accordions {
		width: calc(50% - 1.60715rem);
	}

	.promo-mail-head__input {
		display: none;
	}

	.application__wrapper {
		padding-top: 2.3571rem;
		overflow: hidden;
	}

	.application__image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 70%;
		height: 100%;
	}

	.news__items {
		display: flex;
		flex-wrap: wrap;
		gap: 2.1429rem 3.5714rem;
	}

	.news__item {
		width: calc(50% - 1.7857rem);
	}

	.statistics__head-buttons--hidden {
		display: none;
	}

	.statistics__head-inner.space-between .statistics__head-buttons {
		margin-left: auto;
	}

	.statistics__head-inputs {
		display: none;
	}

	.statistics-control__select {
		min-width: 10.1429rem;

		&.input {
			min-width: auto;
			max-width: 7.2143rem;
		}
	}
}

@media (min-width: 2000.98px) {
	html {
		font-size: 1.2rem;
	}
}

@media (max-width: 1599.98px) {
	html {
		font-size: 0.8rem;
	}
}

@media (max-width: 1199.98px) {
	html {
		font-size: 0.7rem;
	}

	.personal-promo__bg--left {
		width: 50%;
	}

	.personal-promo__bg--center {
		width: 100%;
	}

	.personal-promo__bg--right {
		width: 50%;
	}

	.application__image {
		width: 55%;
	}
}

@media (max-width: 991.98px) {
	html {
		font-size: 1.3rem;
	}

	main {
		overflow: hidden;
	}

	.container-full {
		padding: 0 1.0714rem;
	}

	.content__wrapper {
		flex-direction: column;
	}

	.datepicker-input input {
		padding: 0.7143rem 0.5rem;
		padding-right: 2.8571rem;
		font-size: 0.7857rem;
		line-height: 0.9286rem;
	}

	.datepicker-input__icons {
		right: 0.3571rem;
	}

	.datepicker-input__icon {
		margin-right: 0.2143rem;
	}

	.select2-container--default {
		.select2-selection--single {
			height: 2.3571rem;

			font-size: 0.7857rem;
			line-height: 0.9286rem;
			.select2-selection__rendered {
				padding-right: 1.4286rem;
				padding-left: 0.5714rem;
				line-height: 0.9286rem;
			}

			.select2-selection__arrow {
				right: 0.4286rem;

				b {
					width: 0.6429rem;
					height: 0.6429rem;
				}
			}
		}

		.select2-results > .select2-results__options {
			font-size: 0.7857rem;
			line-height: 0.9286rem;
		}

		.select2-results__option {
			padding: 0.3571rem 0.5rem;
		}
	}

	.select2-dropdown {
		border-radius: 0.7143rem !important;
		padding: 0.3571rem 0;
	}

	.table--large table {
		max-width: 85.5rem;
	}

	.table table {
		font-size: 0.8571rem;
		line-height: 1.0714rem;
	}

	.accordion__trigger {
		padding-right: 1.4286rem;
		padding-left: 1.4286rem;
	}

	.accordion__content {
		padding-right: 1.4286rem;
		padding-left: 4.2143rem;
		font-size: 1rem;
	}

	.pagination {
		margin-top: 1.0714rem;
		padding: 1.2857rem;
		padding-bottom: 1.3571rem;
	}

	.header__menu {
		display: flex;
		position: fixed;
		top: 0;
		left: -100%;
		flex-direction: column;
		align-items: center;
		visibility: hidden;
		z-index: 20;
		transition: 0.4s;
		box-shadow: 0px 0.2857rem 2.8571rem rgba(0, 0, 0, 0.5);
		border-top-right-radius: 1.0714rem;
		border-top-right-radius: 0;
		border-bottom-right-radius: 1.0714rem;
		border-bottom-right-radius: 0;
		background: var(--color-main);
		padding: 3.3571rem 2rem;
		padding-top: 5.3571rem;
		width: 100%;
		max-width: 27.8571rem;

		max-width: 100%;
		height: 100vh;
		overflow-y: auto;

		&.active {
			left: 0;
			visibility: visible;
		}
	}

	.header__control {
		display: none;
	}

	.language__dropdown,
	.header-settings__dropdown {
		box-shadow: 0px 0.2857rem 0.8571rem rgba(0, 0, 0, 0.3);
		background: #272a33;
	}

	.navigation {
		width: 100%;

		nav > ul {
			flex-direction: column;
			margin: 0;

			> li {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 0;
				border-bottom: 0.1429rem solid rgba(95, 83, 38, 0.7);
				width: 100%;
				color: rgba(255, 255, 255, 0.85);
				font-weight: 600;
				font-size: 1.0714rem;
				line-height: 130.9%;
				text-align: center;

				a,
				span {
					display: block;
					padding: 1.0714rem 0;
				}

				&:hover {
					color: var(--color-major);

					ul {
						display: block;
					}
				}

				> ul {
					display: none;
					padding: 1.0714rem 2rem;
					padding-top: 0;
					width: 100%;

					> li {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: 0;
						border-bottom: 2px solid rgba(78, 78, 78, 0.7);
						text-align: center;

						&:first-child a {
							padding-top: 0;
						}

						&:last-child {
							border-bottom: 0;

							a {
								padding-bottom: 0;
							}
						}

						a {
							padding: 15px 0;
						}
					}
				}
			}
		}
	}

	.footer {
		padding: 1.7143rem 0;
	}

	.footer__links ul li {
		font-size: 0.8571rem;
	}

	.footer__social {
		margin: 0 1.7143rem;

		ul li a .icon {
			width: 1.8571rem;
			height: 1.8571rem;
		}
	}

	.navbar {
		display: flex;
		position: fixed;
		top: 0;
		right: -100%;
		flex-direction: column;
		align-items: center;
		visibility: hidden;
		z-index: 20;
		transition: 0.1s;
		box-shadow: 0px 0.2857rem 2.8571rem rgba(0, 0, 0, 0.5);
		border-top-left-radius: 1.0714rem;
		border-top-left-radius: 0;
		border-bottom-left-radius: 1.0714rem;
		border-bottom-left-radius: 0;
		background: var(--color-main);
		padding: 3.3571rem 1.4286rem;
		padding-top: 5.7143rem;
		width: 100%;
		max-width: 27.8571rem;

		max-width: 100%;
		height: 100%;
		overflow-y: auto;
		color: var(--color-white);

		&.active {
			right: 0;
			visibility: visible;
		}
	}

	.navbar__wrapper {
		width: 100%;
		max-width: 21.2143rem;
	}

	.settings-nav {
		margin: 0px 0px 2.1429rem 0px;
		padding: 1.1429rem 1.4286rem;
		padding-bottom: 0;

		ul li {
			&:last-child {
				margin-right: 1.4286rem;
			}

			a,
			button {
				padding-bottom: 1.1429rem;
			}
		}
	}

	.modal__content {
		padding: 3.3571rem 2.1429rem;
		padding-bottom: 3.0714rem;
	}

	.modal__close {
		top: 2.4286rem;
		right: 1.7143rem;
	}

	.modal-form__button {
		margin-top: 1.4286rem;
		max-width: 100%;
	}

	.information {
		margin-top: -3.5714rem;

		margin-top: -3.2143rem;
		margin-left: -1.0714rem;
		background: rgba(255, 255, 255, 0.05);
		padding: 1.4286rem 0px 0px 0px;
		width: calc(100% + 2.1429rem);

		&:hover {
			.information__label .icon {
				transform: rotate(-180deg);
			}

			.information__list {
				display: block;
			}
		}

		&::before {
			display: none;
		}
	}

	.information__list {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 5;
		border-radius: 1.0714rem;
		background: #383b40;
		padding: 1.4286rem;
		width: 100%;

		ul {
			flex-wrap: wrap;
			margin: -0.7143rem;

			li {
				justify-content: flex-start;
				margin: 0.7143rem;
				width: calc(50% - 1.4286rem);
			}
		}
	}

	.banner {
		align-items: center;
		padding: 2.9286rem 1.0714rem 14.2857rem 1.0714rem;
		min-height: 28.5714rem;
	}

	.banner__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.banner__title {
		font-size: 1.7857rem;

		span {
			display: block;
		}
	}

	.banner__button {
		margin: 1.4286rem 0px 0px 0px;
	}

	.banner__image {
		right: -77%;
		width: 265%;
		height: 13.5714rem;
		img {
			-o-object-position: bottom center;
			object-position: bottom center;
		}
	}

	.latest-news {
		width: 100%;
	}

	.latest-news-item__image {
		height: 11.3571rem;
	}

	.profile-chart {
		margin: 0px 0px 1.7143rem 0px;
		padding: 1.4286rem 0.3571rem;
		width: 100%;
	}

	.chart-control__wrapper {
		flex: 1;
		margin-left: 0.3571rem;
	}

	.chart-control-period {
		flex: 1;
	}

	.chart-control-period__title {
		display: none;
	}

	.chart-control-period__select {
		max-width: 12.0714rem;
	}

	.chart-control-period__buttons {
		margin-left: 0.3571rem;
		width: 100%;
		max-width: 10.7143rem;

		max-width: 5.5rem;

		.select2 {
			width: 100% !important;
		}

		> ul {
			display: none;
		}
	}

	.settings__button {
		margin: 2.5rem 0rem 0rem 0rem;
	}

	.settings__title {
		margin: 0rem 0rem 1.4286rem 0rem;
	}

	.settings__text {
		font-size: 1rem;
	}

	.settings-section__inputs .settings-section__input {
		margin: 0rem 0rem 1.7143rem 0rem;

		&:last-child {
			margin: 0;
		}
	}

	.settings-section__inputs--three {
		gap: 1.7143rem 0.5714rem;

		.settings-section__input {
			margin: 0;
			width: calc(50% - 0.2857rem);
		}
	}

	.settings-section__select,
	.settings-section__column {
		margin: 0rem 0rem 1.7143rem 0rem;

		&:last-child {
			margin: 0;
		}
	}

	.main-settings-section__input {
		width: calc(100% - 0.8571rem);
	}

	.main-settings-section__pagination {
		margin-top: 1.4286rem;
	}

	.faq__title {
		margin: 0px 0px 1.4286rem 0px;
	}

	.faq__accordions-wrapper {
		gap: 1.0714rem;
	}

	.create-promo__steps {
		flex-direction: column;
		gap: 0;
		border: 0;

		border-radius: 0;
		background: transparent;

		padding: 0;
	}

	.create-promo-steps__wrapper {
		margin-bottom: 4.2857rem;
		width: 100%;
	}

	.create-promo-steps__sections {
		width: 100%;
	}

	.create-promo-section__head {
		margin-bottom: 2.1429rem;
	}

	.promo-offer__columns {
		flex-direction: column;
		gap: 1.4286rem;
	}

	.promo-offer__column {
		width: 100%;
	}

	.promo-offer-column__items {
		display: flex;
		gap: 0.8571rem;
		padding-right: 1.0714rem;
		padding-bottom: 0.7143rem;
		width: calc(100% + 1.0714rem);
		overflow-x: auto;
	}

	.promo-offer-column__item {
		flex-shrink: 0;
		margin: 0;
		width: 19.9286rem;
	}

	.promo-offer-item__content {
		padding: 1.0714rem 0.7143rem;
		padding-top: 1.4286rem;
	}

	.promo-type__item {
		width: calc(50% - 0.35715rem);
	}

	.promo-link__column {
		width: calc(50% - 0.5357rem);
	}

	.promo-link__language-list ul {
		gap: 0.8571rem;
	}

	.my-promo__head {
		flex-direction: column;
		gap: 2.0714rem;
		margin-bottom: 2.0714rem;
	}

	.my-promo__control {
		justify-content: flex-start;
		gap: 1.4286rem 1.7857rem;
	}

	.my-promo-control__select {
		flex-direction: column;
		align-items: flex-start;
		gap: 0.4286rem;
	}

	.my-promo-control__search {
		max-width: 100%;
	}

	.my-promo-mail__period {
		margin-bottom: 1.4286rem;
	}

	.my-promo-mail__control {
		margin-bottom: 1.7143rem;
	}

	.my-promo-mail__promoId {
		background: var(--color-dark2);
		padding: 10px;
	}

	.promo-mail-period {
		padding: 0.9286rem 1.0714rem;

		.select2-selection.select2-selection--single {
			background: rgba(255, 255, 255, 0.07);
		}
	}

	.promo-mail-period__title {
		width: 100%;
		max-width: 4.2857rem;
		font-size: 0.8571rem;
		line-height: 1.0714rem;
	}

	.promo-mail-control {
		flex-wrap: wrap;
		gap: 1.4286rem;
	}

	.promo-mail-control__select {
		width: calc(50% - 0.92855rem);

		&.input {
			display: none;
		}
	}

	.promo-mail-head {
		flex-wrap: wrap;
		gap: 2.4286rem;
	}

	.personal-promo {
		padding-bottom: 24.2857rem;
	}

	.personal-promo__content {
		margin-top: 2.1429rem;
	}

	.personal-promo__title {
		margin: 0rem 0rem 1.5rem 0rem;
	}

	.personal-promo__bg {
		bottom: -2.1429rem;
	}

	.personal-promo__bg--top {
		display: none;
	}

	.personal-promo__bg--left {
		left: -55%;
		z-index: 1;
		width: 120%;
		height: 27.7143rem;
	}

	.personal-promo__bg--center {
		width: 180%;
		height: 16.3571rem;
	}

	.personal-promo__bg--right {
		right: -35%;
		width: 100%;
		height: 26.4286rem;
	}

	.application__wrapper {
		margin-top: 2.0714rem;
	}

	.application__content {
		padding: 2.5rem;
		padding-top: 2.6429rem;
		padding-right: 1.7857rem;
		padding-bottom: 0;
		overflow: hidden;
	}

	.application__title {
		margin-bottom: 1.6429rem;
	}

	.application__buttons {
		gap: 1.5rem;
		margin-top: 2.1429rem;
	}

	.application__button {
		width: 100%;

		max-width: 178px;
		.btn {
			padding-right: 1.4286rem;
			padding-left: 1.4286rem;
			width: 100%;
		}
	}

	.application__social ul {
		gap: 1.2857rem;
	}

	.application__image {
		margin-top: 0.7143rem;
		margin-left: -7.5rem;
		width: calc(100% + 10rem);

		picture {
			display: flex;
		}
	}

	.news__wrapper {
		margin-top: 1.4286rem;
	}

	.news__head {
		flex-direction: column;
		align-items: flex-start;
		gap: 1.4286rem;
	}

	.news__body {
		margin-top: 2.8571rem;
	}

	.news__item {
		margin-bottom: 1.4286rem;

		&:last-child {
			margin: 0;
		}
	}

	.news-item {
		flex-direction: column;
	}

	.news-item__image {
		width: 100%;
		height: cacl(25.5714rem);
	}

	.news-item__wrapper {
		padding: 1.4286rem;
		padding-top: 1.7143rem;
		padding-right: 1.7143rem;
		padding-left: 2.0714rem;
		width: 100%;
	}

	.notifications__filter {
		margin-top: 14px;
	}

	.notifications__wrapper {
		margin-top: 30px;
	}

	.notifications-filter__select .select2 {
		width: 100% !important;
	}

	.support__wrapper {
		margin-top: 2.1429rem;
	}

	.finance__head-wrapper {
		gap: 1.0714rem 1.4286rem;
	}

	.statistics__period {
		margin-bottom: 1.4286rem;
	}

	.statistics__info-text {
		margin: 0rem 0rem 1.4286rem 0rem;
	}

	.statistics__head {
		margin-bottom: 2.4286rem;
	}

	.statistics__head-wrapper {
		flex-direction: column;
		align-items: stretch;
		margin-bottom: 2rem;
		width: 100%;

		.statistics__head-buttons {
			display: none;
		}
	}

	.statistics__control {
		flex: 1;
	}

	.statistics__head-inner {
		flex-direction: column;
		align-items: stretch;
		gap: 2.1429rem;
	}

	.statistics__head-checkboxs-wrapper {
		gap: 2.8571rem 1.7857rem;

		.checkboxs {
			width: calc(50% - 0.89285rem);
			max-width: 100%;
		}
	}

	.statistics-control__select {
		width: calc(50% - 0.92855rem);

		&.input {
			display: none;
		}
	}
}

@media (max-width: 767.98px) {
	html {
		font-size: 1.15rem;
	}
}

@media (max-width: 575.98px) {
	html {
		font-size: 14px;
	}

	.banner__image {
		width: 300%;
	}

	.latest-news__item:last-child {
		margin: 0;
	}

	.create-promo-steps__title {
		color: rgba(255, 255, 255, 0.9);
		font-size: 1.7857rem;
		line-height: 2.1429rem;
	}

	.create-promo-steps__trigger {
		margin-bottom: 1.0714rem;
	}

	.promo-offer-item__image {
		height: 8rem;
	}

	.promo-offer-item__link {
		flex-direction: column;
		gap: 0.3571rem;
		font-size: 0.8571rem;

		.icon {
			width: 1.0714rem;
			height: 1.0714rem;
		}
	}

	.promo-offer-item__selected {
		padding: 1.0714rem 0.7143rem;
	}

	.promo-link__head {
		flex-direction: column;
		align-items: flex-start;
	}

	.promo-link__add {
		width: 100%;
	}

	.promo-link__add-input {
		flex: 1;
		max-width: 100%;
	}

	.news-item__image {
		height: 16rem;
	}
}

@media (max-width: 349.98px) {
	html {
		font-size: 0.8rem;
	}
}

@media (max-width: 299.98px) {
	html {
		font-size: 0.64rem;
	}
}

@media (max-width: 70.8557rem) {
	.my-promo .table table {
		min-width: 85.5rem;
	}
}

@media (min-width: 576px) and (max-width: 991.98px) {
	.latest-news__items {
		display: flex;
		flex-wrap: wrap;
		margin: -0.7143rem;

		.simplebar-content {
			display: flex;
			flex-wrap: wrap;
			margin: -0.7143rem;
		}
	}

	.latest-news__item {
		margin: 0.7143rem;
		width: calc(50% - 1.4286rem);
	}
}

@supports (-webkit-touch-callout: none) {
	.header__menu,
	.navbar {
		height: -webkit-fill-available;
	}
}
