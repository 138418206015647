@import '../../../styles/variables.scss';

.label {
	display: block;
	width: 100%;

	font-size: 12px;
	line-height: 15px;

	@media (max-width: 575.98px) {
		font-size: 10px;
		line-height: 12px;
	}

	> span {
		display: block;
		margin: 0px 0px 9px 0px;
		font-weight: 700;

		@media (max-width: 575.98px) {
			margin: 0px 0px 6px 0px;
		}
	}
}

textarea {
	width: 100%;
	height: 7.1429rem;
	overflow-y: auto;
	resize: none;
}

.input__wrapper {
	position: relative;
	> svg {
		fill: var(--color-white);
		position: absolute;
		top: 50%;

		left: 18px;
		transform: translateY(-50%);
		opacity: 0.5;
		width: 15px;
		height: 15px;
		pointer-events: none;
		& + .input {
			padding-left: 43px;

			@media (max-width: 575.98px) {
				padding-left: 33px;
			}
		}

		@media (max-width: 575.98px) {
			left: 13px;
			width: 14px;
			height: 14px;
		}
	}

	.input,
	input,
	textarea {
		display: block;

		transition: 0.3s;
		border: 0.5px solid rgba(255, 255, 255, 0.2);
		border-radius: 10px;
		background: #181a20;

		padding: 15px;
		padding-right: 30px;
		padding-bottom: 14px;
		width: 100%;

		color: rgba(255, 255, 255, 0.5);
		color: var(--color-white);

		font-weight: 400;
		font-size: 12px;
		line-height: 15px;

		@media (max-width: 575.98px) {
			border-radius: 8px;
			padding: 11px;
			padding-right: 30px;

			min-height: 42px;
		}

		&::placeholder {
			color: rgba(255, 255, 255, 0.5);
		}

		&:focus {
			border-color: rgba(255, 255, 255, 0.6);
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-text-fill-color: var(--color-white) !important;
			transition: background-color 3000s ease-in-out 0s;
			-webkit-box-shadow: 0 0 0px 1000px var(--color-grey) inset;
		}
	}

	.input__icon {
		display: flex;

		position: absolute;
		top: 50%;
		right: 10px;
		justify-content: center;
		align-items: center;
		transform: translateY(-50%);
		z-index: 3;
		transition: 0.3s;
		cursor: pointer;
		border-radius: 5px;
		background: var(--color-major);
		width: 24px;
		height: 24px;

		&:hover {
			opacity: 0.7;
		}

		svg {
			fill: var(--color-main);
			width: 16px;
			height: 16px;

			@media (max-width: 575.98px) {
				width: 12px;
				height: 12px;
			}
		}

		@media (max-width: 575.98px) {
			right: 8px;
			width: 19px;
			height: 19px;
		}
	}

	.iconBefore {
		display: flex;
		position: absolute;
		top: 50%;

		left: 0.5714rem;
		justify-content: center;
		align-items: center;
		transform: translateY(-50%);
		z-index: 3;
		width: 1.7143rem;
		height: 1.7143rem;

		pointer-events: none;

		svg {
			fill: var(--color-silver);
			width: 100%;
			height: 100%;
		}

		& + input {
			padding-left: 2.8571rem !important;
		}
	}

	.double {
		display: flex;
		position: relative;

		&::before {
			position: absolute;
			top: 50%;

			left: 50%;
			transform: translate(-50%, -50%);
			pointer-events: none;
			content: '-';

			color: var(--color-silver);
		}

		input {
			max-width: 8.5714rem;
			&:first-child {
				border-right: 0;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			+ input {
				border-left: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
}

.error {
	color: #f9533d;
	font-weight: 400;
	font-size: 12px;
	line-height: 130%;

	&__input {
		border-color: #f9533d;
	}
}
