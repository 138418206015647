@import '../../../styles/variables.scss';

.best {
	padding: 10px 0;

	overflow: hidden;

	@media (max-width: 991.98px) {
		position: relative;
		margin-top: -20px;
		padding-top: 60px;
		padding-bottom: 15px;

		.money {
			position: absolute;
			top: -7px;
			left: 0;
			width: 100%;
			height: 135px;
			pointer-events: none;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	position: relative;
	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		border-radius: 50%;

		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(252, 213, 53, 0.1) 0%,
			rgba(252, 213, 53, 0) 100%
		);
		width: calc(100% - 100px);
		height: calc(100% - 40px);
		pointer-events: none;
		content: '';

		@media (max-width: 991.98px) {
			background: radial-gradient(
				50% 50% at 50% 50%,
				rgba(252, 213, 53, 0.07) 0%,
				rgba(252, 213, 53, 0) 100%
			);
			width: 100%;
			height: calc(100% + 178px * 2);
		}

		@media (max-width: 575.98px) {
			width: calc(100% + 230px * 2);
		}
	}
}

.wrapper {
	display: flex;

	position: relative;
	justify-content: space-between;
	align-items: center;
	z-index: 2;

	@media (max-width: 991.98px) {
		flex-direction: column-reverse;
	}
}

.title {
	margin: 0px 0px 57px 0px;

	text-align: center;

	@media (max-width: 575.98px) {
		align-self: center;
		margin: 0px 0px 40px 0px;

		width: 100%;

		max-width: 259px;
	}
}

.content {
	padding: 30px 0;
	width: 100%;

	max-width: 430px;

	@media (max-width: 991.98px) {
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 0;

		max-width: 100%;
	}
}

.items {
	width: 100%;
	max-width: 604px;

	@media (max-width: 1499.98px) {
		margin-left: -5%;
		width: 110%;
		max-width: 550px;
	}

	@media (max-width: 1199.98px) {
		margin: 0;
		width: 100%;
		max-width: 605px;
	}

	@media (max-width: 991.98px) {
		align-self: center;
	}

	&__image {
		margin: 0px 0px 6px 0px;
		width: 144px;
		height: 127px;
		// filter: drop-shadow(0px 4px 30px rgba(252, 213, 53, 0.5));

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -8px;
		li {
			margin: 0 8px;

			width: calc(100% / 3 - 16px);

			@media (max-width: 575.98px) {
				width: calc(100% / 2 - 16px);
			}

			display: flex;
			flex-direction: column;
			align-items: center;

			color: rgba(255, 255, 255, 0.9);

			font-weight: 600;
			font-size: 14px;
			line-height: 130.4%;
			text-align: center;
			/* or 21px */

			text-align: center;

			@media (max-width: 575.98px) {
				font-size: 12px;
			}

			span {
				display: block;
				width: 100%;
				max-width: 180px;

				@media (max-width: 575.98px) {
					max-width: 136px;
				}
			}
		}
	}
}
.image {
	&__left {
		margin-left: -20px;

		padding: 0px 0px 37.42% 0px;
		width: 100%;
		max-width: 522px;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain !important;
		}

		@media (max-width: 1499.98px) {
			margin-left: -60px;
		}

		@media (max-width: 991.98px) {
			margin: 39px 0px 0px 0px;
			margin-right: -20px;
			margin-right: -10%;
			margin-left: -10%;

			// padding: 0px 0px 80% 0px;
			padding: 0px 0px 120% 0px;
			width: 120%;
			max-width: 100%;
			max-width: 120%;
		}
	}
	&__right {
		margin-right: -20px;

		padding: 0px 0px 36.5% 0px;
		width: 100%;
		max-width: 489px;

		@media (max-width: 1499.98px) {
			margin-right: -60px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain !important;
		}

		@media (max-width: 991.98px) {
			display: none;
		}
	}
}
