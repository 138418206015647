@import '../../../styles/variables.scss';

.social {
  ul {
    display: flex;
    align-items: center;
    margin: -10.5px;

    li {
      margin: 10.5px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 35px;
        height: 35px;

        position: relative;

        transition: 0.3s;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
