.user-profile {
	border: 0.0714rem solid #474747;
	border-radius: 0.5714rem;
	background: rgba(255, 255, 255, 0.01);
	padding: 1.7143rem;

	height: fit-content;

	@media (max-width: 54.7857rem) {
		margin-bottom: 2.8571rem;
	}

	&__img {
		display: flex;
		justify-content: center;

		align-items: center;
		width: 7.1429rem;
		height: 7.1429rem;

		svg {
			fill: var(--color-white);

			opacity: 0.5;
			width: 100%;
			height: 100%;
		}
	}

	&__header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-weight: 500;
	}

	&__input {
		display: none;
	}

	&__label {
		display: none;
	}

	&__mail {
		margin-top: 1.1429rem;
		color: #fff;
		font-size: 1.1429rem;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}

	&__social-btn {
		display: inline-block;
		position: relative;

		cursor: pointer;
		border-radius: 0.5714rem;
		background-color: #1b1f28;
		padding: 0.5714rem 0.5714rem 0.5714rem 2.2857rem;
		color: var(--color-silver);
		font-weight: 400;
		font-size: 0.8571rem;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}

		img {
			position: absolute;
			top: 50%;
			left: 1.1429rem;
			flex-shrink: 0;
			transform: translate(-50%, -50%);
			width: 1.7143rem;
			height: 1.7143rem;
			object-fit: contain;
		}
	}

	&__description {
		margin-top: 0.2857rem;
		color: #80858f;
		font-size: 0.8571rem;
	}

	&__button-edit {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-top: 1.1429rem;
		background-color: var(--color-dark);

		// padding: 0;
		width: 100%;
	}

	&__social {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5714rem;
		margin: 1.1429rem 0 0;
	}

	&__logout-btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-top: 1.1429rem;

		box-shadow: none;

		padding: 1.1429rem;
		width: 100%;
	}

	&-list {
		margin: 0;
		padding: 0;
		list-style: none;

		&__item {
			margin-top: 1.1429rem;
		}

		&__item-title {
			color: var(--color-silver);
			font-weight: 400;
			font-size: 1rem;
		}

		&__item-text {
			display: block;
			margin-top: 0.2857rem;
			color: var(--color-white);
			font-weight: 500;
			font-size: 1.1429rem;
		}
	}

	@media (max-width: 73.1429rem) {
		padding: 1.1429rem;
	}

	@media (max-width: 54.7857rem) {
		position: relative;
		padding: 0 1.1429rem;

		&__header {
			padding: 1.7143rem 0 0.8571rem;
		}

		&__main {
			overflow: hidden;
		}

		&__input {
			&:checked ~ .user-profile__wrapper {
				transition: max-height 0.25s ease-in;
				max-height: 35.7143rem;
			}
			&:checked ~ label {
				.user-profile__arrow {
					transform: rotate(225deg);
				}
				span {
					&:nth-child(1) {
						display: none;
					}
					&:nth-child(2) {
						display: block;
					}
				}
			}
		}

		&__label {
			display: flex;
			position: absolute;
			bottom: -3.2143rem;
			left: 0;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			padding: 0.7857rem;
			width: 100%;
			color: #80858f;
			font-size: 1rem;
			span {
				&:nth-child(2) {
					display: none;
				}
			}
		}

		&__arrow {
			transition: none;
		}

		&__wrapper {
			transition: max-height 0.15s ease-out;
			max-height: 0;
		}

		&__button-edit {
			margin-top: 0;
		}

		&__logout-btn {
			margin-bottom: 1.7143rem;
		}
	}
}
