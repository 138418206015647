.page {
	display: flex;

	gap: 1.7143rem;

	@media (max-width: 767.98px) {
		flex-direction: column;
	}
}
.wrapper {
	display: flex;
	flex-direction: column;
	gap: 1.7143rem;
}
.inner {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;

	gap: 1.7143rem;
	width: 22vw;
	max-width: 30.8571rem;

	@media (max-width: 767.98px) {
		width: 100%;
		max-width: 100%;
	}
}

.content {
	display: flex;
	flex: 1;
	flex-direction: column;

	gap: 3.4286rem 1.7143rem;
	width: 67vw;

	@media (max-width: 767.98px) {
		gap: 1.7143rem;
		width: 100%;
	}
}

.tabs {
	border: 0.0714rem solid #474747;
	border-radius: 0.5714rem;
	background: rgba(255, 255, 255, 0.01);
	padding: 1.7143rem;

	.nav {
		margin-bottom: 1.7143rem;
		background: transparent;
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
		&::after {
			display: none;
		}
	}

	.tablist {
		justify-content: flex-start;
	}
}

.links {
	border: 0.0714rem solid #474747;
	border-radius: 0.5714rem;
	background: rgba(255, 255, 255, 0.01);
	padding: 0.5714rem 1.7143rem;

	ul {
		display: flex;
		flex-direction: column;

		li {
			border-bottom: 1px solid #3838388b;
			&:last-child {
				border: 0;
			}
			a {
				display: flex;
				align-items: center;
				gap: 0.7143rem;

				transition: 0.3s;

				padding: 1.1429rem 0;

				color: var(--color-light);

				svg {
					fill: var(--color-light);

					flex-shrink: 0;

					transition: 0.3s;
					width: 1.7143rem;
					height: 1.7143rem;
				}

				&:hover {
					color: var(--color-white);

					svg {
						fill: var(--color-white);
					}
				}
			}
		}
	}
}
