@import '../../../styles/variables.scss';

.button {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	gap: 8px;
	transition: 0.3s;
	cursor: pointer;
	outline: none;
	box-shadow: 0px 0px 30px rgba(252, 213, 53, 0.5);
	border-radius: 10px;
	background: var(--color-major);

	padding: 16.5px 20px;

	color: var(--color-main);

	font-weight: 800;
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	svg {
		fill: var(--color-main);
		transition: 0.3s;
		width: 21px;
		height: 21px;
	}

	&:hover {
		transform: translateY(-2px);
		background: var(--color-white);
		color: var(--color-main);

		svg {
			transform: translateX(4px);
		}
	}

	&:active {
		transform: translateY(0);
	}

	&:disabled {
		pointer-events: none;
	}
	&:global(.button--disabled) {
		opacity: 0.5;
		pointer-events: none;
	}
	&:global(.button--small) {
		box-shadow: 0px 0px 10px rgba(252, 213, 53, 0.4);
		border-radius: 6px;
		background: var(--color-major);

		padding: 8px 15.5px;

		font-weight: 600;
		font-size: 13px;
		line-height: 14px;
		text-transform: none;

		@media (max-width: 575.98px) {
			font-size: 11px;
			line-height: 13px;
		}
	}
	&:global(.button--minor) {
		box-shadow: none;
		background: rgba(255, 255, 255, 0.1);
		color: var(--color-white);

		@media (max-width: 991.98px) {
			transform: none;

			&:hover {
				transform: none;
			}
		}

		svg {
			fill: var(--color-white);
		}

		&:hover {
			background: rgba(255, 255, 255, 0.4);
			color: var(--color-white);

			svg {
				fill: var(--color-white);
				transform: none;
			}
		}
	}
}
