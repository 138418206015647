.wallets-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	border-radius: 0.5714rem;
	background: rgba(255, 255, 255, 0.05);
	padding: 1.1429rem;

	height: 10.7143rem;

	@media (max-width: 767.98px) {
		height: 8.7143rem;
	}

	&__logo {
		width: 5.3571rem;
		max-width: 100%;
		object-fit: contain;
	}

	&__active {
		background: #008066;
	}

	&__status-text {
		color: #fff;
	}

	&__top-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 1.7143rem;
	}

	&__check {
		overflow: hidden;
		color: #fff;
		font-weight: 500;
		font-size: 1.7143rem;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__status-wrap {
		display: flex;
		align-items: center;
	}
	&__status-img {
		display: flex;

		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		margin: 0 0.2857rem 0 0;

		width: 1.1429rem;
		height: 1.1429rem;

		svg {
			fill: var(--color-white);
			width: 100%;
			height: 100%;
		}
	}
	&__status-text {
		color: #a7aab1;
		font-size: 0.7143rem;
		line-height: 1.4;
	}
}

.burger {
	display: flex;

	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	width: 1.7143rem;
	height: 1.7143rem;

	&:hover {
		svg {
			fill: var(--color-white);
		}
	}

	svg {
		fill: var(--color-light);

		transition: 0.3s;
		width: 100%;
		height: 100%;
	}
}

.wallet-dropdown {
	position: relative;

	&__item {
		display: flex;
		align-items: center;
		font-size: 0.9286rem;
		line-height: 1.2;
	}
	&__list {
		position: absolute;
		top: -45%;
		right: 80%;
		z-index: 100;
		box-sizing: border-box;
		margin: 0;
		box-shadow: 0 0.1429rem 0.5714rem rgba(0, 0, 0, 0.4);
		border: 0.0714rem solid #232834;
		border-radius: 0.5714rem;
		background: var(--color-dark);
		padding: 0.7143rem 0;
		width: 16rem;
		list-style: none;
		text-align: left;
	}

	&__item-btn {
		display: flex;
		align-items: center;

		gap: 0.7143rem;

		padding: 0.5714rem 1.1429rem;
		width: 100%;

		&:hover {
			span {
				color: var(--color-white);
			}

			svg {
				fill: var(--color-white);
			}
		}

		svg {
			fill: var(--color-light);

			flex-shrink: 0;

			transition: 0.3s;
			width: 1.1429rem;
			height: 1.1429rem;
		}

		span {
			flex: 1;
			transition: 0.3s;

			margin: 0;
			color: var(--color-light);
			text-align: left;
			white-space: normal;
		}
	}
}

@media screen and (max-width: 137.1429rem) {
	.wallets-item__check {
		font-size: calc(1.2857rem + 6 * ((100vw - 54.8571rem) / (1920 - 768)));
	}
}

@media screen and (max-width: 54.8571rem) {
	.wallets-item__check {
		font-size: 1.2857rem;
	}
}
