@import '../../../styles/variables.scss';

.stats {
	position: relative;
	border-radius: 20px;

	background: var(--color-main);
	padding-top: 40px;

	overflow: hidden;

	@media (min-width: 992px) {
		&::before {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);

			background: linear-gradient(
				180deg,
				#fcd535 -87.18%,
				rgba(252, 213, 53, 0) 104.31%
			);
			width: 3px;
			height: 100%;
			content: '';
		}
	}
}

.money_mobile {
	position: absolute;
	top: 9px;
	left: 0;
	width: 100%;
	height: 105px;
	pointer-events: none;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.wrapper {
	display: flex;

	position: relative;
	flex-direction: column;
	align-items: center;
	z-index: 2;
}

.inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	text-align: center;
}

.logo {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0px 0px 16px 0px;
	color: var(--color-major);

	font-weight: 700;
	font-size: 25px;
	line-height: 30px;
	letter-spacing: 0.14em;
	text-align: center;
	text-align: center;
	text-transform: uppercase;

	span {
		position: relative;

		small {
			position: absolute;
			top: -2px;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 2px;

			background-color: var(--color-white);
			padding: 1px 3px;
			color: var(--color-main);
			font-size: 8px;
			line-height: 1;
		}
	}

	&__image {
		margin: 0px 0px 10px 0px;
		width: 100%;
		max-width: 243px;
		height: 50px;

		@media (max-width: 575.98px) {
			margin: 0px 0px 7px 0px;
			width: 184px;
			height: 38px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	@media (max-width: 575.98px) {
		margin: 0px 0px 26px 0px;
		font-size: 18.8897px;
		line-height: 23px;
	}
}

.columns {
	display: flex;
	flex-wrap: wrap;

	@media (min-width: 768px) {
		margin: -25px;

		.column {
			flex: 1;
			margin: 25px;
			width: calc(100% / 2 - 50px);
		}
	}
}

.column {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	@media (max-width: 767.98px) {
		margin: 0px 0px 40px 0px;

		&:last-child {
			margin: 0;
		}
	}
}

.head {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin: 0px 0px 40px 0px;

	@media (max-width: 575.98px) {
		margin: 0px 0px 30px 0px;
	}
}

.text {
	width: 100%;

	max-width: 511px;
	color: rgba(255, 255, 255, 0.69);
}

.items {
	position: relative;
	width: 100%;
	max-width: 395px;
	&::before {
		position: absolute;
		top: 0;

		left: 50%;
		transform: translateX(-50%);

		border-radius: 50%;

		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(252, 213, 53, 0.1) 0%,
			rgba(252, 213, 53, 0) 100%
		);
		width: 864.5265502929688px;
		height: 578.1367797851562px;
		pointer-events: none;
		content: '';
	}

	@media (max-width: 575.98px) {
		margin: 0 auto;
		width: 90%;
	}

	& > * {
		position: relative;
		z-index: 2;
	}

	ul {
		li {
			position: relative;
		}
	}

	&__image {
		margin-bottom: -65px;
		width: 90px;
		height: 90px;

		@media (max-width: 575.98px) {
			margin-bottom: -50px;
			width: 64px;
			height: 64px;
		}

		@media (min-width: 576px) {
			position: absolute;
			top: -23px;
			left: 50%;
			transform: translateX(-50%);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 12px;

		background: rgba(255, 255, 255, 0.03);
		padding: 15px 25px;
		padding-top: 67px;
		width: 100%;
		height: 100%;

		@media (max-width: 575.98px) {
			padding: 15px 20px;
			padding-top: 60px;
			height: calc(100% - 14px);
		}

		position: relative;
		overflow: hidden;
		&::before {
			position: absolute;
			top: -27px;

			left: 0;

			border-radius: 50%;

			background: radial-gradient(
				50% 50% at 50% 50%,
				rgba(233, 198, 0, 0.15) 0%,
				rgba(233, 198, 0, 0) 100%
			);
			width: 216.98px;
			height: 262.72px;
			pointer-events: none;
			content: '';
		}

		& > * {
			position: relative;
			z-index: 2;
		}
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		gap: 32px 16px;

		@media (max-width: 575.98px) {
			gap: 8px;
		}
		li {
			width: calc(100% / 2 - 8px);

			@media (max-width: 575.98px) {
				width: calc(100% / 2 - 4px);

				font-size: 12px;
			}

			display: flex;
			flex-direction: column;
			align-items: center;

			color: rgba(255, 255, 255, 0.9);

			font-weight: 600;
			font-size: 14px;
			line-height: 140.4%;
			text-align: center;

			text-align: center;
		}
	}
}

.money {
	margin-top: 40px;
	margin-right: -120px;

	margin-left: -65px;
	width: 100%;
	max-width: 247px;
	height: 100%;

	pointer-events: none;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	@media (max-width: 767.98px) {
		display: none;
	}
}

.image {
	position: relative;
	z-index: 1;
	// margin-top: -92px;
	margin-top: -28%;

	width: 100%;
	// height: 501.73px;

	height: auto;
	// z-index: 2;

	pointer-events: none;

	@media (max-width: 1499.98px) {
		margin-top: -25%;
	}

	@media (max-width: 991.98px) {
		margin: -10px 0px 0px -28%;
		width: 160%;

		height: 501.73px;
	}

	@media (max-width: 575.98px) {
		margin-top: -22%;
		// height: 187px;

		height: 300px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: bottom;
	}

	&__inner {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		width: 100%;
		height: 630px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
		}
	}
}
