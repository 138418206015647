.modal {
	position: relative;

	max-width: 37.8571rem;
}

.inner {
	padding: 1.7143rem;

	@media (max-width: 41.1414rem) {
		padding: 1.7143rem 1.1429rem;
	}
}

.close {
	display: flex;

	position: absolute;
	top: 1.0714rem;
	right: 1.0714rem;
	justify-content: center;
	align-items: center;

	opacity: 0.7;
	z-index: 5;

	transition: 0.3s;

	cursor: pointer;
	width: 1.4286rem;
	height: 1.4286rem;

	&:hover {
		opacity: 1;
	}

	svg {
		fill: var(--color-white);
		width: 100%;
		height: 100%;
	}
}

.auth {
	&Tabs {
		display: flex;
		align-items: center;
		gap: 0.5714rem;
		margin-bottom: 1.7143rem;
	}

	&Tab {
		transition: 0.3s;
		cursor: pointer;
		outline: none;
		border: none;
		border-radius: 2.2857rem;
		background: transparent;
		padding: 0.5rem 0.7143rem;
		color: var(--color-light);
		font-weight: 600;
		font-size: 1rem;
		line-height: 1.2857rem;
		letter-spacing: -0.02em;

		&:hover {
			color: var(--color-white);
		}

		&.active {
			background: var(--color-major);
			pointer-events: none;
			color: var(--color-main);
		}
	}

	&Help {
		margin: 1.7143rem 0;
		font-weight: 600;
		font-size: 0.8571rem;
		line-height: 1.1429rem;
		letter-spacing: -0.02em;

		&Btn {
			cursor: pointer;
			border: none;
			background-color: transparent;
			padding: 0;

			color: var(--color-major);

			&:hover {
				color: var(--color-silver);
			}
		}
	}
	&Banner {
		position: relative;
		width: 100%;
		height: 7.5714rem;

		@media (max-width: 54.8557rem) {
			height: 4.7857rem;
		}

		&Img {
			position: absolute;
			top: 0;
			right: 0;
			left: -1.7143rem;
			max-width: calc(100% + 1.7143rem);
			height: auto;
		}
	}
}

.image {
	width: 100%;
	max-width: 21.6429rem;

	height: 32.9286rem;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	@media (max-width: 54.8557rem) {
		margin: 0rem 0rem 1.0714rem 0rem;
		max-width: 100%;

		height: 21.4286rem;
	}
	@media (max-width: 41.1414rem) {
		height: 15rem;
	}
}

.form {
	display: flex;
	flex-direction: column;

	gap: 1.7143rem;
}

.title {
	font-weight: 700;
	font-size: 1.4286rem;
	line-height: 1.5;
}

.inputs {
	display: flex;
	flex-direction: column;

	gap: 0.8571rem;

	&__wrapper {
		display: flex;

		gap: 0.8571rem;

		> * {
			width: 50%;

			@media (max-width: 767.98px) {
				width: 100%;
			}
		}
	}
}

.socials {
	display: flex;

	gap: 0.8571rem;

	.dropdown {
		width: 40%;

		@media (max-width: 41.1414rem) {
			width: 40%;

			&__trigger {
				span {
					display: none;
				}

				> svg {
					margin-left: auto;
				}
			}
		}
	}
}

.subscription {
	padding: 10.0002px 0;
}

.datepicker {
	:global(.react-datepicker-wrapper) {
		input {
			border: 0.0357rem solid rgba(255, 255, 255, 0.2);
			background: #181a20;

			padding: 1.0714rem;
			padding-bottom: 1rem;

			font-weight: 400;
			font-size: 0.8571rem;
			line-height: 1.0714rem;

			@media (max-width: 41.1414rem) {
				padding: 0.7857rem;
				min-height: 3rem;
			}
		}
	}
}

.button {
	margin-top: 0.8571rem;
	width: 100%;
	a,
	button,
	input {
		padding: 0.7143rem 2.2857rem;
		width: 100%;

		font-weight: 700;
		font-size: 1rem;
		line-height: 1.5;

		text-transform: none;
	}
}

.agree {
	margin-top: 1.1429rem;

	color: rgba(255, 255, 255, 0.5);

	font-weight: 400;
	font-size: 0.8571rem;
	line-height: 157.4%;

	@media (max-width: 41.1414rem) {
		margin: 1.7143rem 0rem 0rem 0rem;
	}

	a {
		color: var(--color-major);

		&:hover {
			color: var(--color-white);
		}
	}
}

.auth {
	margin-top: 1.2143rem;

	color: rgba(255, 255, 255, 0.5);

	font-size: 0.8571rem;
	line-height: 157.4%;

	a,
	button {
		color: var(--color-major);
		font-weight: 800;

		&:hover {
			color: var(--color-white);
		}
	}
}

.popup__field {
	display: flex;
	flex-direction: column;

	margin-top: 0.8571rem;

	legend {
		color: var(--color-silver);
		font-size: 0.8571rem;
		line-height: 1.2;
	}
}
