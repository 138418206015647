@import '../../../styles/variables.scss';

.hero {
	position: relative;
	padding-top: 90px;
	padding-bottom: 40px;

	overflow: hidden;
}

.background {
	position: absolute;
	top: 0;
	left: 0;

	z-index: 4;
	width: 100%;
	height: 100%;
	pointer-events: none;

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__money {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
		transition: 0.3s;
		width: 100%;
		height: 100%;
		pointer-events: none;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.wrapper {
	display: flex;

	position: relative;
	flex-direction: column;
	align-items: center;
	z-index: 3;
}
.inner {
	display: flex;

	position: relative;
	flex-direction: column;
	align-items: center;
	width: 100%;

	text-align: center;
	&::before {
		position: absolute;
		top: -63.4609375px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 50%;

		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(252, 213, 53, 0.2) 0%,
			rgba(252, 213, 53, 0) 100%
		);
		width: 1600px;
		height: 836.2146606445312px;
		pointer-events: none;
		content: '';

		@media (max-width: 767.98px) {
			top: -46px;
			background: radial-gradient(
				50% 50% at 50% 50%,
				rgba(252, 213, 53, 0.2) 0%,
				rgba(252, 213, 53, 0) 100%
			);
			width: 100%;
			height: 773px;
		}
	}

	& > * {
		position: relative;
		z-index: 2;
	}
}

.logo {
	margin: 0px 0px 30px 0px;

	color: rgba(255, 255, 255, 0.85);

	font-weight: 600;
	font-size: 20.2139px;
	line-height: 25px;

	letter-spacing: 0.12em;

	text-align: center;
	text-transform: uppercase;

	&__image {
		margin: 0px 0px 9px 0px;
		width: 215px;
		height: 47px;

		@media (max-width: 575.98px) {
			margin: 0px 0px 6px 0px;
			width: 152px;
			height: 32px;
		}

		opacity: 0.8;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	@media (max-width: 575.98px) {
		font-size: 13.6234px;
		line-height: 17px;
	}
}

.text {
	margin: 0px 0px 30px 0px;

	max-width: 1040px;

	color: rgba(255, 255, 255, 0.85);

	font-weight: 700;
	font-size: 40px;
	line-height: 49px;

	@media (max-width: 575.98px) {
		font-size: 30px;
		line-height: 130.9%;
	}
}

.button {
	margin: 0px 0px 9px 0px;

	a,
	button {
		padding-right: 63px;
		padding-left: 63px;
	}

	@media (max-width: 767.98px) {
		margin: 0px 0px 58px 0px;
	}
}

.image {
	margin-top: -18%;

	padding: 0px 0px 61.1% 0px;
	width: calc(100% + 40px);
	pointer-events: none;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain !important;
	}

	@media (max-width: 991.98px) {
		margin-top: -60px;
	}

	@media (max-width: 767.98px) {
		padding: 0 0 68.5%;

		width: calc(100% + 50px);
	}
}

.items {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;

	padding-top: 52px;
	width: 100%;

	@media (max-width: 575.98px) {
		padding-top: 32px;
	}

	&__line {
		position: absolute;
		top: -125px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;

		max-width: 571px;

		height: 314px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		@media (max-width: 575.98px) {
			display: none;
		}
	}

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(252, 213, 53, 0.02) 0%,
			rgba(252, 213, 53, 0) 100%
		);
		width: 1600px;
		height: 513.2942504882812px;
		pointer-events: none;
		content: '';

		@media (max-width: 575.98px) {
			top: -50px;
			transform: translateX(-50%);

			background: linear-gradient(
				180deg,
				#fcd535 -87.18%,
				rgba(252, 213, 53, 0) 104.31%
			);
			width: 2px;
			height: calc(100% + 100px);
		}
	}
	ul {
		display: flex;

		position: relative;
		justify-content: center;
		align-items: center;
		z-index: 2;

		margin: -14px;
		width: 100%;

		@media (max-width: 575.98px) {
			flex-direction: column;
			margin: 0;
		}

		li {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 14px;
			border-radius: 10px;
			background: #202020;

			padding: 25px;
			padding-bottom: 23px;
			width: 100%;

			max-width: 256px;

			overflow: hidden;

			color: rgba(255, 255, 255, 0.7);
			text-align: center;

			@media (max-width: 575.98px) {
				margin: 0px 0px 22px 0px;
				padding: 25px;

				font-size: 15px;
				line-height: 145.77%;
				&:last-child {
					margin: 0;
				}
			}

			position: relative;

			span {
				font-weight: 700;
			}

			strong {
				display: block;
				margin: 5px 0px 0px 0px;
				color: var(--color-major);
				font-weight: 700;
				font-size: 25px;
				line-height: 145.77%;

				@media (max-width: 575.98px) {
					font-size: 23px;
					line-height: 145.77%;
				}
			}

			& > * {
				position: relative;
				z-index: 2;
			}
			&::before {
				position: absolute;
				top: -35px;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 50%;

				background: radial-gradient(
					50% 50% at 50% 50%,
					rgba(233, 198, 0, 0.15) 0%,
					rgba(233, 198, 0, 0) 100%
				);
				width: 470.79px;
				height: 448.67px;
				pointer-events: none;
				content: '';
			}
		}
	}
}
