@import '../../../styles/variables.scss';

.faq {
  margin-top: -10px;
  background: #15161c;
  border-radius: 20px;

  position: relative;
  z-index: 3;

  padding-top: 85px;
  padding-bottom: 110px;

  @media (max-width: 991.98px) {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  @media (max-width: 575.98px) {
    padding-top: 56px;
    padding-bottom: 52px;
  }
}

.wrapper {
  position: relative;
  z-index: 2;
}

.accordion {
  :global(.icon) {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    background: rgba(233, 198, 0, 0.5);
    border-radius: 10px;

    @media (max-width: 575.98px) {
      width: 40px;
      height: 40px;
      margin-right: 18px;
    }

    position: relative;
    &::before {
      position: absolute;
      content: '';
      transition: 0.3s;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18.5px;
      height: 3px;
      background: #1c1d23;
      border-radius: 6px;

      @media (max-width: 575.98px) {
        width: 16.48px;
      }
    }
    &::after {
      position: absolute;
      content: '';
      transition: 0.3s;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 18.5px;
      width: 3px;
      background: #1c1d23;
      border-radius: 6px;

      @media (max-width: 575.98px) {
        height: 16.48px;
      }
    }

    margin-right: 29px;
  }
}
