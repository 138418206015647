.faq {
	border-radius: 20px;
	background: var(--color-main);

	padding: 40px 0;

	&__inner {
		position: relative;
	}

	&__head {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 24px;

		@media (max-width: 767.98px) {
			align-items: center;
		}

		h1 {
			font-feature-settings: 'tnum' on, 'lnum' on;
			margin: 0;
			color: #fff;
			font-weight: 700;
			font-size: 32px;
			line-height: 1.2;

			@media (max-width: 991.98px) {
				font-size: 24px;
			}
		}
	}

	&__body {
		// display: flex;

		// @media (max-width: 767.98px) {
		// 	flex-direction: column;
		// }

		.faq__accordion {
			.faqItem {
				cursor: pointer;
				margin: 0;

				border-bottom: 1px solid var(--color-silver);
				border-radius: 0;
				background: transparent;
				padding: 0;

				.faqItemHeader {
					display: flex;
					position: relative;
					justify-content: space-between;
					align-items: center;

					gap: 16px;
					margin: 0;
					padding: 0;
					height: auto;
					min-height: 82px;

					color: #fff;
					font-weight: 600;
					font-size: 20px;

					@media (max-width: 575.98px) {
						min-height: 60px;
						font-size: 16px;
					}

					h2 {
					}

					svg {
						fill: var(--color-major);

						flex-shrink: 0;

						margin-left: 20px;
						width: 20px;
						height: 20px;
					}

					:global(.accordion-button) {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 0;
						width: 100%;

						text-align: left;
					}
				}

				.faqItemBody {
					margin: 0;

					padding: 0 40px 24px 0;

					color: var(--color-white);
					font-weight: 500;
					font-size: 14px;
					line-height: 1.5;
				}
			}
		}
	}
}
