@import '~normalize.css';
@import './variables.scss';

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	-webkit-tap-highlight-color: transparent;
	background-color: var(--color-grey);

	color: var(--color-white);

	font-weight: 400;
	font-size: 15px;
	line-height: 145.4%;
	font-family: 'Montserrat', sans-serif;

	@media (max-width: 575.98px) {
		font-size: 14px;
	}
}

label {
	margin: 0;
}

input,
textarea {
	appearance: none;
	outline: none;
	box-shadow: none;

	border: none;
	background-image: none;
	background-color: transparent;

	&::placeholder {
		transition: 0.3s;
	}
	&:focus {
		&::placeholder {
			opacity: 0;
		}
	}
}

button {
	appearance: none;
	transition: 0.3s;
	cursor: pointer;
	outline: none;
	border-width: 0;
	background-color: transparent;
	padding: 0;
	font-style: inherit;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	text-decoration: none;
}

b {
	font-weight: bold;
}

a {
	transition: 0.3s;

	color: inherit;
	text-decoration-line: none;

	&:hover {
		color: var(--color-major);
		text-decoration: none;
	}
}

select {
	appearance: none;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

html,
body {
	height: 100%;
}

#root {
	display: flex;
	flex-direction: column;
}

main {
	flex: 1 1 auto;
}

footer {
	flex-shrink: 0;
}

/* Global styles */

.iresponsive {
	position: relative;

	img,
	video {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		border: none;
		width: 100%;
		height: 100%;
	}
}

.icon {
	display: inline-flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	svg {
		// fill: inherit;
		transition: 0.3s;
		width: 100%;
		height: 100%;
	}
}

.container {
	margin: 0 auto;

	padding: 0 30px;
	width: 100%;
	max-width: 1340px;

	@media (max-width: 991.98px) {
		padding: 0 20px;
	}

	@media (max-width: 575.98px) {
		padding: 0 15px;
	}
}

h1,
.h1 {
	margin: 0;
	font-weight: 700;
	font-size: 40px;
	line-height: 55px;

	@media (max-width: 991.98px) {
		font-size: 38px;
		line-height: 120%;
	}

	@media (max-width: 575.98px) {
		font-size: 36px;
	}
}

h2,
.h2 {
	margin: 0;
	font-weight: 700;
	font-size: 30px;
	line-height: 37px;
	text-transform: uppercase;

	@media (max-width: 991.98px) {
		font-size: 28px;
		line-height: 140%;
	}

	@media (max-width: 575.98px) {
		font-size: 25px;
		line-height: 30px;
	}
}
h3,
.h3 {
	margin: 0;
	font-weight: 700;
	font-size: 25px;
	line-height: 138%;

	@media (max-width: 991.98px) {
		font-size: 23px;
	}

	@media (max-width: 575.98px) {
		font-size: 21px;
	}
}
h4,
.h4 {
	margin: 0;
	font-weight: 700;
	font-size: 20px;
	line-height: 138%;

	@media (max-width: 991.98px) {
		font-size: 19px;
	}

	@media (max-width: 575.98px) {
		font-size: 18px;
	}
}

.modal-project {
	padding-right: 20px;
	padding-left: 20px;
	max-width: 1210px;

	@media (max-width: 575.98px) {
		padding-right: 00px;
		padding-left: 0px;
	}

	.modal-content {
		box-shadow: 0px 4px 40px #000000;
		border-radius: 25px;
		background: #131519;

		padding: 43px 88px;
		padding-bottom: 74px;

		@media (max-width: 1199.98px) {
			padding: 30px;
		}

		@media (max-width: 767.98px) {
			padding: 30px 20px;
		}
	}
}

.modal-registration {
	max-width: 749px;
}
.modal-thanks {
	max-width: 459px;
}

.modal-dialog {
	@media (max-width: 767.98px) {
		margin: 32px 16px;
		min-height: calc(100% - 80px);
	}
}

.modal-content {
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	background: var(--color-main);
	padding: 0;

	color: var(--color-white);
}

.accordion {
	.accordion-item {
		position: relative;

		margin: 0px 0px 25px 0px;
		border: 0;
		border-radius: 0;
		border-radius: 20px;
		background: transparent;

		background: rgba(255, 255, 255, 0.03);
		width: 100%;

		&:last-child {
			margin-bottom: 0;
		}
		&:last-child {
			margin: 0;
		}

		@media (max-width: 575.98px) {
			margin: 0px 0px 20px 0px;
		}

		.accordion-header {
			position: relative;
			padding: 0;

			font-weight: 600;
			font-size: 25px;
			line-height: 30px;
			user-select: none;

			@media (max-width: 575.98px) {
				font-size: 18px;
				line-height: 137.4%;
			}

			.accordion-button {
				padding: 28px 70px;
				padding-bottom: 24px;

				@media (max-width: 575.98px) {
					padding: 24px 21px;
					padding-left: 30px;
				}

				display: flex;

				position: static;
				align-items: center;

				transition: 0.3s;

				outline: none;
				box-shadow: none;
				border-radius: 0;
				background-color: transparent;
				color: inherit;

				font-size: inherit;

				&:hover {
					color: var(--color-major);
				}
				&:focus {
					outline: none;
					box-shadow: none;
					border: 0;
				}

				&::after {
					display: none;
				}

				&:not(.collapsed) .icon {
					&:after {
						transform: translate(-50%, -50%) rotate(-90deg);
					}
				}
			}

			&:not(.collapsed) {
				box-shadow: none;
			}
		}

		.accordion-body {
			position: relative;
			z-index: 2;

			padding: 0px 52px 38px 145px;
			font-size: 15px;
			line-height: 152.4%;

			@media (max-width: 575.98px) {
				padding: 0px 25px 21px 30px;
			}

			.table-faq {
				@media (max-width: 767.98px) {
					width: 100%;
					overflow-x: auto;

					table {
						width: 800px;
					}
				}
			}

			table {
				width: 100%;

				td {
					vertical-align: baseline;

					padding: 10px;

					@media (max-width: 575.98px) {
						padding: 10px 2.5px;
					}
				}
				thead {
					font-weight: 700;
				}
				@media (max-width: 575.98px) {
					font-size: 14px;
				}
			}

			p {
				margin: 15px 0;

				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			ul {
				margin: 15px 0;

				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.react-datepicker-popper {
	&[data-placement^='top'] .react-datepicker .react-datepicker__triangle {
		&::before {
			border-top-color: #383b40;
		}
		&::after {
			border-top-color: #383b40;
		}
	}
	&[data-placement^='bottom'] .react-datepicker .react-datepicker__triangle {
		&::before {
			border-bottom-color: #383b40;
		}
		&::after {
			border-bottom-color: #383b40;
		}
	}

	.react-datepicker {
		display: flex;
		border: 0;
		border-radius: 1.0714rem;
		background-color: #383b40;
		color: var(--color-white);
		font-weight: 400;
		font-size: 0.9286rem;
		line-height: 1.1429rem;

		font-family: inherit;

		@media (max-width: 575.98px) {
			flex-wrap: wrap;
		}

		padding: 1rem;

		.react-datepicker__navigation {
			width: 2rem;
			height: 2rem;
			&--previous {
				top: 1.2rem;
				left: 1.2rem;
			}
			&--next {
				top: 1.2rem;
				right: 1.2rem;
			}

			&-icon {
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 1.1429rem;
				height: 1.1429rem;
				font-size: 0;

				&::before {
					display: none;
				}

				&::after {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 1.1429rem;
					height: 1.1429rem;
					content: '';
				}

				&--previous {
					&::after {
						background-image: url("data:image/svg+xml,%3Csvg width='11' height='18' viewBox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L2 9L10 17' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
					}
				}
				&--next {
					&::after {
						background-image: url("data:image/svg+xml,%3Csvg width='11' height='18' viewBox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 17L9 9L1 0.999999' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
					}
				}
			}
		}

		.react-datepicker__header {
			border: 0;
			border-radius: 1.0714rem !important;
			background-color: #383b40;
			color: var(--color-white);
			font-weight: 400;
			font-size: 0.9286rem;
			line-height: 1.1429rem;

			font-family: inherit;
		}

		.react-datepicker__current-month,
		.react-datepicker-time__header,
		.react-datepicker-year-header {
			color: var(--color-white);
			font-weight: 400;
			font-size: 0.9286rem;
			line-height: 1.1429rem;
		}

		.react-datepicker__month {
			// border: 0.0625rem solid rgba(255, 255, 255, 0.2);
			// border-radius: 0.625rem;
		}

		.react-datepicker__day-names {
			margin-top: 10px;
			margin-bottom: -15px;
		}

		.react-datepicker__day-names,
		.react-datepicker__week {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.react-datepicker__day-name,
		.react-datepicker__day,
		.react-datepicker__time-name {
			display: inline-flex;
			justify-content: center;
			align-items: center;

			transition: 0.3s;

			border-radius: 0;

			width: 1.75rem;
			height: 1.75rem;
			color: var(--color-white);
			font-weight: 500;
			font-size: 0.9286rem;
			line-height: 1.1429rem;

			&:hover {
				background: var(--color-major);
				color: var(--color-black);
			}
		}

		.react-datepicker__day--selected,
		.react-datepicker__day--in-selecting-range,
		.react-datepicker__day--in-range,
		.react-datepicker__month-text--selected,
		.react-datepicker__month-text--in-selecting-range,
		.react-datepicker__month-text--in-range,
		.react-datepicker__quarter-text--selected,
		.react-datepicker__quarter-text--in-selecting-range,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__year-text--selected,
		.react-datepicker__year-text--in-selecting-range,
		.react-datepicker__year-text--in-range {
			background: rgba(252, 213, 53, 0.2);
		}

		.react-datepicker__day--keyboard-selected,
		.react-datepicker__month-text--keyboard-selected,
		.react-datepicker__quarter-text--keyboard-selected,
		.react-datepicker__year-text--keyboard-selected {
			background: var(--color-major);
			color: var(--color-black);
		}
		.react-datepicker__day.react-datepicker__day--range-end {
			background: var(--color-major);
			color: var(--color-black);
		}
	}
}

.react-select-container {
	min-width: 10.1429rem;
	line-height: 1.0714rem;

	@media (max-width: 991.98px) {
		min-width: auto;
	}

	@media (min-width: 992px) {
		width: 100%;
		max-width: 10.1429rem;
	}

	&.input-select {
		@media (min-width: 992px) {
			width: 100%;
			max-width: 100%;
		}

		.react-select__control {
			border: 0.0714rem solid rgba(255, 255, 255, 0.1);
			border-radius: 0.7143rem;
			background: rgba(255, 255, 255, 0.03);
			min-height: 3.229rem;
			font-weight: 500;
			font-size: 0.9286rem;
			line-height: 1.1429rem;
		}
	}

	.react-select__control {
		display: flex;
		align-items: center;

		transition: 0.3s;
		cursor: pointer;
		border: 0;
		border-radius: 0.7143rem;
		background: rgba(255, 255, 255, 0.07);
		min-height: 2.5rem;
		color: var(--color-white);
		font-weight: 600;
		font-size: 0.8571rem;
		line-height: 1.0714rem;

		&:focus {
			box-shadow: none;
		}
		&:not(:focus) {
			box-shadow: none;
		}

		&:hover {
			background: rgba(255, 255, 255, 0.15);
		}

		&--menu-is-open {
			box-shadow: none;
			background: rgba(255, 255, 255, 0.15);
		}

		.react-select__input-container {
			color: var(--color-white);
		}
	}

	.react-select__placeholder {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.react-select__single-value {
		color: var(--color-white);
	}
	.react-select__menu {
		transition: 0.3s;
		border: 0;
		border-radius: 0.7143rem;
		background: #33353a;
		color: var(--color-white);
		font-weight: 600;
		font-size: 0.8571rem;
		line-height: 1.0714rem;
	}

	.react-select__option {
		transition: 0.3s;

		cursor: pointer;
		background: transparent;

		&:hover {
			background: transparent;
			color: var(--color-major);
		}

		&--is-selected {
			background: transparent;
			pointer-events: none;
			color: var(--color-major);
		}
	}

	.react-select__value-container {
		padding: 0px 0.5rem 0px 1.25rem;

		@media (max-width: 991.98px) {
			padding: 0px 0.5rem 0px 0.5rem;
		}
	}

	.react-select__indicator-separator {
		display: none;
	}
	.react-select__input-container {
		margin: 0;
		padding: 0;
	}
	.react-select__indicator {
		margin-right: 1rem;
		padding: 0;

		@media (max-width: 991.98px) {
			margin-right: 0.5rem;
		}

		svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}
}

.accordion.accordions {
	.accordion-custom {
		margin: 0rem 0rem 1.0714rem 0rem;
		border-radius: 0.7143rem;
		background: rgba(255, 255, 255, 0.03);

		&:last-child {
			margin: 0;
		}

		// .accordion__trigger

		.accordion-button {
			display: flex;
			align-items: center;
			gap: 1rem;

			transition: 0.3s;

			cursor: pointer;

			padding: 1.7857rem 2.8571rem;

			font-weight: 600;
			font-size: 1.0714rem;
			line-height: 1.2857rem;
			user-select: none;
			text-align: left;

			@media (max-width: 991.98px) {
				padding-right: 1.4286rem;
				padding-left: 1.4286rem;
			}

			&.collapsed {
				.trigger-icon {
					&::after {
						opacity: 1;
					}
				}
			}

			&:hover {
				color: var(--color-major);
			}
		}

		// .accordion__trigger-icon

		.trigger-icon {
			display: flex;

			position: relative;

			flex-shrink: 0;
			justify-content: center;
			align-items: center;

			box-shadow: 0rem 0rem 0.7143rem rgba(252, 213, 53, 0.55);
			border-radius: 0.3571rem;

			background: var(--color-major);
			width: 1.7857rem;
			height: 1.7857rem;
			&::before {
				position: absolute;

				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				transition: 0.3s;

				border-radius: 0.2857rem;
				background-color: var(--color-main);
				width: 0.8571rem;
				height: 0.1429rem;
				content: '';
			}
			&::after {
				position: absolute;

				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				opacity: 0;

				transition: 0.3s;

				border-radius: 0.2857rem;
				background-color: var(--color-main);
				width: 0.1429rem;
				height: 0.8571rem;
				content: '';
			}
		}

		.accordion-body {
			transition: 0.3s;

			padding-right: 3rem;
			padding-bottom: 1.7857rem;
			padding-left: 5.6429rem;
			font-size: 1.0714rem;
			line-height: 139.9%;

			@media (max-width: 991.98px) {
				padding-right: 1.4286rem;
				padding-left: 4.2143rem;
				font-size: 1rem;
			}
		}
	}
}

.create-promo-offers {
	display: flex;
	flex-direction: column;

	gap: 0.7143rem;
}

// .profile-chart {
//   min-height: 20rem;
// }

.modal-promo {
	.modal-content {
		margin: 0 auto;
		box-shadow: 0rem 0.2857rem 2.8571rem rgb(10 10 10 / 40%);
		border-radius: 1.4286rem;
		background: var(--color-modal);
		padding: 2.6429rem 3.4286rem;
		padding-bottom: 2.7143rem;
		width: 100%;
		max-width: 35.7143rem;

		@media (max-width: 991.98px) {
			padding: 3.3571rem 2.1429rem;
			padding-bottom: 3.0714rem;
		}
	}
}

.PhoneInputCountry {
	position: absolute;
	top: 50%;

	left: 18px;
	transform: translateY(-50%);

	width: 15px;
	height: 15px;

	@media (max-width: 575.98px) {
		left: 13px;
	}

	// .PhoneInputCountryIcon {
	// 	width: 100%;
	// 	height: auto;
	// }

	& + input {
		background: transparent;
		background: #181a20;
		padding-left: 43px;
		width: 100%;
		color: var(--color-white);

		@media (max-width: 575.98px) {
			padding-left: 33px;
		}
	}
	.PhoneInputCountrySelect {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
	}
}
