:root {
	--color-white: #ffffff;
	--color-black: #000000;
	--color-silver: #797a7f;
	--color-grey: #181a20;
	--color-main: #15161c;
	--color-dark: #11131a;
	--color-major: #fcd535;
	--color-light: rgba(255, 255, 255, 0.7);
	--color-minor-4: #bf9f00;
	--font-typold: 'Typold', 'Montserrat', sans-serif;
	--font-montserrat: 'Montserrat', sans-serif;
	--color-complementary-1: #35fcd5;
	--color-complementary-2: #35b2fc;
	--color-complementary-3: #d535fc;
}
