@import '../../../styles/variables.scss';

.header {
	position: fixed;

	top: 0;
	left: 0;
	z-index: 10;

	background-color: var(--color-dark);

	padding-top: 11px;
	padding-bottom: 11px;
	width: 100%;

	& ~ main {
		padding: 0;
	}
}

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo {
	display: flex;
	flex-shrink: 0;

	width: 101.25px;
	height: 30px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	@media (max-width: 991.98px) {
		display: none;
		width: 115px;

		height: 24px;
	}
}

.menu {
	display: flex;
	flex: 1;
	align-items: center;
	margin-left: 45px;

	@media (max-width: 991.98px) {
		position: fixed;

		top: -100%;
		left: 0;
		flex-direction: column;
		visibility: hidden;
		opacity: 0;
		z-index: 20;
		transition: 0.3s;
		margin: 0;
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.5);
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px;

		background: #15161c;

		padding: 40px 20px;

		width: 100%;

		height: 100vh;
		overflow-y: auto;
		color: var(--color-white);

		@supports (-webkit-touch-callout: none) {
			body {
				height: -webkit-fill-available;
			}
		}

		&.active {
			top: 0;
			visibility: visible;
			opacity: 1;
		}

		.logo {
			display: block;
		}
	}

	@media (min-width: 992px) {
		.logo {
			display: none;
		}
		.social {
			display: none;
		}
	}
}

.burger {
	&__icon {
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		width: 31px;
		height: 31px;

		svg {
			fill: var(--color-major);
			width: 100%;
			height: 100%;
		}
	}

	@media (min-width: 992px) {
		display: none;
	}
}
.close {
	display: flex;
	position: absolute;
	top: 15px;
	right: 15px;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	z-index: 1;

	width: 20px;
	height: 20px;

	svg {
		fill: var(--color-major);
		width: 100%;
		height: 100%;
	}

	@media (min-width: 992px) {
		display: none;
	}
}

.buttons {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.button {
	margin-right: 10px;

	&:last-child {
		margin: 0;
	}

	@media (max-width: 991.98px) {
		margin-right: 9px;
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	visibility: hidden;

	opacity: 0;

	z-index: 15;

	transition: 0.3s;
	background: var(--color-grey);
	width: 100%;
	height: 100%;

	&.active {
		visibility: visible;
		opacity: 0.8;
	}

	@media (min-width: 992px) {
		display: none;
	}
}

.navigation {
	margin-right: 20px;

	@media (max-width: 1199.98px) {
		margin-right: 15px;
	}

	@media (max-width: 991.98px) {
		margin: 20px 0px 39px 0px;
		width: 100%;
	}
}

.language {
	margin-left: 16px;
}
