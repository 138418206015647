.balances {
	// .{s.balances__title}

	&__title {
		margin: 0;

		margin: 0 0 1.7143rem;

		font-weight: 600;
		font-size: 2rem;
		line-height: 1.18;

		@media (max-width: 70.8557rem) {
			font-size: calc(1.4286rem + 8 * ((100vw - 22.8571rem) / (1920 - 320)));
			text-align: center;
		}
	}

	// .{s.balances__wrapper}

	&__wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;

		gap: 10px;

		margin: 0 0 1.7143rem;
		border: 0.0714rem solid #474747;
		border-radius: 0.5714rem;

		background: rgba(255, 255, 255, 0.01);
		padding: 1.1429rem;

		&__withdraw {
			padding-bottom: 5px;
			height: 50px;
			span {
				color: #474747 !important;
				font-size: 1rem !important;
			}
		}

		span {
			display: block;
			margin: 0 0 0.5714rem;
			color: var(--color-light);
			font-size: 1.4286rem;
			line-height: 1.3;

			@media (max-width: 70.8557rem) {
				font-size: 1.1429rem;
			}
		}

		strong {
			position: relative;
			font-size: 2.2857rem;
			line-height: 1.19;

			@media (max-width: 70.8557rem) {
				font-size: 2rem;
			}
		}
	}
}

.content {
	@media (min-width: 992px) {
		border: 0.0714rem solid #474747;
		border-radius: 0.5714rem;

		background: rgba(255, 255, 255, 0.01);

		padding: 1.7143rem;
	}
}

.subtitle {
	display: inline-block;
	margin: 0 0 1.7143rem;

	border-bottom: 0.2857rem solid var(--color-major);

	padding: 0.5714rem 1.1429rem;
	color: var(--color-white);

	font-weight: 500;
	font-size: 1.2857rem;
	line-height: 1.3;

	@media (max-width: 70.8557rem) {
		font-size: 1.1429rem;
	}
}

.counts {
	width: auto;
	min-width: auto;
}
