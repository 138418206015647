.dropdown {
	position: relative;

	&__trigger {
		display: flex;
		position: relative;

		align-items: center;
		transition: 0.3s;
		cursor: pointer;
		outline: none;
		border: 0.5px solid rgba(255, 255, 255, 0.2);
		border-radius: 10px;

		background: var(--color-grey);
		padding: 8px 16px;
		width: 100%;
		height: 100%;

		min-height: 45px;
		font-size: 12px;
		line-height: 15px;

		user-select: none;

		@media (max-width: 575.98px) {
			border-radius: 8px;
			min-height: 42px;
		}

		&:focus,
		&:hover {
			border-color: var(--color-silver);
			background: var(--color-dark);
		}

		.icon {
			display: flex;
			flex-shrink: 0;
			justify-content: center;
			align-items: center;
			margin-right: 6px;
			margin-left: -4.8px;
			width: 24px;

			height: 24px;

			svg {
				fill: var(--color-light);
				width: 100%;
				height: 100%;
			}
		}

		.text {
			display: flex;
			flex: 1 1;
			align-items: center;
			overflow-x: hidden;
			line-height: 24px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.iconArrow {
			fill: var(--color-light);
			transform: rotate(0);
			transition: 0.3s;
			margin-right: -4px;
			margin-left: 8px;
			width: 16px;
			height: 16px;
			pointer-events: none;
		}
	}

	&__inner {
		position: absolute;

		top: 100%;
		right: 0;

		left: 0;
		transform: translateY(0);
		z-index: 2;

		margin-top: 8px;

		min-width: 180px;
	}

	&__content {
		z-index: 1;
		box-shadow: 2px 2px 20px var(--color-main);
		border-width: 2px;
		border-style: solid;
		border-color: var(--color-grey);

		border-radius: 10px;
		background-color: var(--color-dark);
		padding: 2px;
		font-size: 14px;
	}
}

.dropdownMenu {
	&__inner {
		overflow-x: hidden;
		overflow-y: auto;
	}

	&__link {
		display: flex;
		align-items: center;
		transition: 0.3s;
		cursor: pointer;
		border: none;
		border-radius: 6px;
		background-color: transparent;
		padding: 8px 10px;
		width: 100%;
		color: var(--color-white);

		font-size: 12px;
		text-align: left;

		&:hover {
			&:hover {
				background: var(--color-main);
				color: var(--color-white);
			}
		}

		.icon {
			display: flex;
			flex-shrink: 0;
			justify-content: center;
			align-items: center;
			margin-right: 6px;
			margin-left: -4.8px;
			width: 24px;

			height: 24px;

			svg {
				fill: var(--color-light);
				width: 100%;
				height: 100%;
			}
		}

		.text {
			display: flex;
			flex: 1 1;
			align-items: center;

			overflow-x: hidden;

			line-height: 24px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
