@import '../../../styles/variables.scss';

.faq {
  margin-top: -10px;
  background: #15161c;
  border-radius: 20px;

  position: relative;
  z-index: 3;

  padding-top: 44px;
  padding-bottom: 91px;

  @media (max-width: 991.98px) {
    padding-top: 50px;
    padding-bottom: 80px;
  }

  @media (max-width: 575.98px) {
    padding-top: 56px;
    padding-bottom: 52px;
  }
}

.wrapper {
  position: relative;
  z-index: 2;
}

.text {
  max-width: 950px;
  width: 100%;

  font-size: 16px;
  line-height: 162.4%;

  b {
    font-weight: 800;
  }

  ul,
  ol {
    margin: 26px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    li {
      padding-left: 25px;
      position: relative;

      ol,
      ul {
        margin: 26px 0px 0px 0px !important;
      }
      &::after {
        position: absolute;
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        top: 10px;
        left: 10px;
        background-color: var(--color-white);
      }
      margin: 0px 0px 26px 0px;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    counter-reset: ollist1;
    > li {
      &::before {
        counter-increment: ollist1;
        content: counter(ollist1) '. ';
      }

      ol {
        counter-reset: ollist2;
        li {
          &::before {
            counter-increment: ollist2;
            content: counter(ollist1) '.' counter(ollist2) '. ';
          }
        }
      }
    }
  }

  p {
    margin: 0;
  }

  h2,
  h3 {
    margin: 50px 0px 39px 0px;

    font-weight: 600;

    @media (max-width: 575.98px) {
      margin: 40px 0px 26px 0px;
    }

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
