@import '../../../styles/variables.scss';

.language {
	position: relative;
	width: 100%;

	&:global(.language--reverse) {
		.dropdown {
			top: inherit;
			bottom: calc(100% + 5px);

			background-color: var(--color-main);
		}
	}
	&:global(.language--cabinet) {
		.trigger {
			background: transparent !important;
			padding: 0;
		}
	}

	.trigger {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.3s;
		cursor: pointer;
		border-radius: 6px;
		background: rgba(45, 51, 64, 0.6);

		padding: 5px;

		color: rgba(255, 255, 255, 0.7);

		font-weight: 500;
		font-size: 12px;
		line-height: 165.8%;
		user-select: none;
		text-transform: uppercase;

		&:hover {
			background: rgba(255, 255, 255, 0.3);
			color: var(--color-white);
		}

		&__icon {
			display: flex;
			flex-shrink: 0;
			justify-content: space-between;
			align-items: center;

			border-radius: 100px;
			width: 20px;
			height: 20px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		span {
			margin-right: auto;
		}

		&__arrow {
			display: flex;
			justify-content: center;
			align-items: center;

			transition: 0.3s;
			margin-left: 3.5px;
			width: 6px;
			height: 6px;

			&.active {
				transform: rotate(-180deg);
			}

			svg {
				fill: var(--color-white);
				width: 100%;
				height: 100%;
			}
		}
	}

	.dropdown {
		position: absolute;
		top: calc(100% + 10px);
		right: 0;

		box-shadow: 0px 5px 20px var(--color-grey);
		border-radius: 6px;
		// display: none;

		background: rgba(45, 51, 64, 0.7);
		width: 135px;

		ul {
			li {
				display: flex;
				align-items: center;
				transition: 0.3s;
				cursor: pointer;
				margin: 0px 0px 2px 0px;

				border-radius: 6px;
				padding: 5px 11px;

				color: var(--color-white);

				font-weight: 500;
				font-size: 12px;
				line-height: 165.8%;
				&:last-child {
					margin: 0;
				}
				&.active {
					background: rgba(45, 51, 64, 1);
					pointer-events: none;
				}

				.item {
					display: flex;
				}

				.trigger__icon {
					margin-right: 6px;
				}

				&:hover {
					background: rgba(255, 255, 255, 0.3);
					color: var(--color-white);
				}
			}
		}
	}
}
