@import '../../../styles/variables.scss';

.navigation {
  ul {
    display: flex;
    align-items: center;

    margin: 0 -13px;

    @media (max-width: 1199.98px) {
      margin: -5px;
    }

    @media (max-width: 991.98px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
    }

    li {
      margin: 0 13px;

      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;

      @media (max-width: 1199.98px) {
        margin: 5px;
      }

      @media (max-width: 991.98px) {
        text-align: center;

        width: 100%;

        margin: 0;

        border-bottom: 2px solid rgba(95, 83, 38, 0.7);
      }

      a {
        position: relative;
        transition: 0.3s;
        cursor: pointer;

        span {
          transition: 0.3s;
        }

        @media (max-width: 991.98px) {
          display: block;
          padding: 13px 0;

          font-weight: 600;
          font-size: 15px;
          line-height: 130.9%;

          text-align: center;
          text-transform: uppercase;

          color: rgba(255, 255, 255, 0.85);
        }
      }
    }
  }
}
