.tabs {
	.nav {
		margin-bottom: 1.7143rem;
		background: transparent;
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
		&::after {
			display: none;
		}
	}

	.tablist {
		justify-content: flex-start;
	}
}

.finance-wallets {
	&__content {
		display: flex;
		flex-direction: column;

		gap: 1.7143rem;

		.title {
			margin: 0;
			color: var(--color-white);
			font-weight: 600;
			font-size: 2rem;
			line-height: 1.18;

			text-transform: none;

			@media (max-width: 991.98px) {
				font-size: calc(20px + 8 * ((100vw - 320px) / (1920 - 320)));
			}
		}
	}

	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;

		gap: 0.8571rem;
	}
}

.cards {
	display: flex;
	flex-wrap: wrap;
	gap: 1.1429rem;

	.card {
		width: calc(100% / 3 - (1.1429rem / 1.5));

		@media (max-width: 767.98px) {
			width: calc(100% / 2 - (1.1429rem / 2));
		}
	}
}
