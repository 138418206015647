.modal {
  padding: 54px;
  padding-top: 38px;

  position: relative;

  @media (max-width: 991.98px) {
    padding: 40px 32px;
  }

  @media (max-width: 767.98px) {
    padding: 20px 18px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image {
  max-width: 339px;
  width: 100%;

  height: 241px;

  margin: 0px 0px 27px 0px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 767.98px) {
    margin: 0px 0px 15px 0px;
    max-width: 100%;
  }
  @media (max-width: 575.98px) {
    height: 210px;
  }
}

.title {
  font-weight: 600;
  font-size: 23px;
  line-height: 123.4%;

  text-align: center;
  text-transform: uppercase;

  margin-bottom: 18px;

  @media (min-width: 1200px) {
    width: calc(100% + 30px);
    margin-right: -15px;
  }

  @media (max-width: 575.98px) {
    font-size: 20px;
    margin: 0px 0px 15px 0px;
  }
}

.text {
  text-align: center;
}

.button {
  margin: 18px 0px 0px 0px;
  width: 100%;
  a,
  button,
  input {
    width: 100%;

    font-weight: 700;
    font-size: 12px;
    line-height: 157.4%;

    padding: 14px 20px;

    text-transform: none;
    @media (max-width: 575.98px) {
      font-weight: 700;
      font-size: 10px;
      line-height: 157.4%;

      padding: 10px 20px;
      border-radius: 7.74929px;
    }
  }

  @media (max-width: 575.98px) {
    margin: 20px 0px 0px 0px;
  }
}
